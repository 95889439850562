import React from 'react'
import  {ModelCard}  from '../component/ModelCard';

//Icons
import { BiSolidShoppingBags } from "react-icons/bi";



export const Retail = () => {
    const routes = [
        {path: "/onlineShopper", name: "Online Shopper", desc: "Online Shopper Test", Icon: BiSolidShoppingBags }
      ];
    
    
  return (
    <div className="flex flex-col justify-center items-center gap-4">
    <h1 className="text-4xl text-defualt-b font-bold flex justify-center items-center pt-5">Retail Demos</h1>
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 px-[100px]">
        {routes.map((route, index) => (
        //   <div key={index} className="border rounded-lg overflow-hidden shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-300 relative bg-white p-5">
        //   <NavLink to={route.path} className="p-4 text-lg font-semibold text-default hover:text-point-blue transition-colors duration-300">
        //       <div className="flex justify-between items-center">
        //           {route.name}
        //       </div>
        //       <p className="text-sm text-gray-600 mt-2">
        //           {route.desc}
        //       </p>
        //   </NavLink>
        // </div>
        <ModelCard key={index} ModelName={route.name} link={route.path} desc={route.desc} Icon={route.Icon}/>
        ))}</div>

  </div>
)
}
