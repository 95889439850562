import React, { useEffect, useState } from 'react';
import { FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle } from 'react-icons/fi';

const AlertPopup = ({ type, message, onClose }) => {
  const iconSize = 24;
  const [countdown, setCountdown] = useState(7); 

  // Icons and styles based on alert type
  const iconStyles = {
    info: { icon: <FiInfo size={iconSize} />, color: 'blue-500', bgColor: 'blue-100' },
    success: { icon: <FiCheckCircle size={iconSize} />, color: 'green-500', bgColor: 'green-100' },
    error: { icon: <FiXCircle size={iconSize} />, color: 'red-500', bgColor: 'red-100' },
    warning: { icon: <FiAlertCircle size={iconSize} />, color: 'yellow-500', bgColor: 'yellow-100' }
  };

  const { icon, color, bgColor } = iconStyles[type] || {};

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      onClose();
    }
  }, [countdown, onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-5 bg-white rounded-lg shadow-lg">
        <div className="flex items-center mb-5">
          <div className={`flex items-center justify-center w-8 h-8 text-white bg-${bgColor} rounded-full`}>
            {icon}
          </div>
          <div className="flex-grow ml-3 text-sm font-medium text-gray-800">
            {message}
          </div>
          <button onClick={onClose} className="ml-auto -mx-1.5 -my-1.5 bg-transparent text-black rounded-lg p-1.5 focus:outline-none focus:ring-2 focus:ring-gray-300">
            <FiXCircle size={iconSize} />
          </button>
        </div>
        <div className={`absolute bottom-0 left-0 h-1 bg-${color} transition-width duration-1000 ease-linear`} style={{ width: `${(5 - countdown) * 20}%` }}></div>
      </div>
    </div>
  );
};

export default AlertPopup;