//import ReactEcharts from 'echarts-for-react';
import axios from 'axios';
function MlModule(Props) {

  var parsedData;
  var data_ver_x;
  var data_ver_y;
  //console.log(Props.data.fileType);
  parsedData=Props.data.parsedData;
  data_ver_x = parsedData.map(parsedData =>  parsedData[Props.data.x_axis]);
  data_ver_y = parsedData.map(parsedData =>  parsedData[Props.data.y_axis]);
  //console.log("data_ver_y:",data_ver_y);


  async function postData() {
    try {
        //const usersName = JSON.stringify({ name: 'John Doe' });
        const mlData = JSON.stringify({ x: data_ver_x,  y: data_ver_y});
        const response = await axios.post('http://localhost:8000/send_data', {
          mlData,    
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

      if (response.status === 200) {
      
        const data = response.data;
  
        return data;
      } else {

        throw new Error('Received a non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      
      throw error;
    }
  }

  postData()
  .then((data) => {
    // Use the data here
    console.log('Received data:', data);
  })
  .catch((error) => {
    // Handle any errors here
    console.error('Error:', error);
  });

}

export default MlModule;

  //['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  //[820, 932, 901, 934, 1290, 1330, 1320]

