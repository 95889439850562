import React, { useEffect, useState } from 'react';
import Prism from "prismjs";
import "../prism.css"; 

import { FaRegCopy } from "react-icons/fa";


export const CodeSnippet = ({ code }) => {
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
      Prism.highlightAll();
    }, [code]);

    // Function to copy code to clipboard
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(code);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    // Reset code Copied Message
    useEffect(() => {
        if (copySuccess !== '') {
            setTimeout(() => {
                setCopySuccess('');
            }, 2000); // Reset message after 2 seconds
        }
    }, [copySuccess]);

    return (
        <div className='relative'>
        <div className='absolute top-0 left-0 right-0 bg-defualt-b flex justify-between items-center p-2 text-xl h-[35px]'>
            <h1 className='text-white text-lg font-semibold ml-2 pt-3'>Python Code</h1>
            <div className='py-2 flex items-center'>
                {copySuccess && <span className='text-white font-semibold text-lg mr-2'>{copySuccess}</span>}
                <button className='text-2xl text-white ' onClick={copyToClipboard}><FaRegCopy /></button>
            </div>
        </div>
        <pre className='pt-10'>
          <code className='language-javascript'>
            {code}
          </code>
        </pre>
      </div>
    );
}
