import React from 'react';
import { NavLink } from 'react-router-dom';
import { MdPlayCircleOutline } from 'react-icons/md';

export const ModelCard = ({ ModelName, link, desc, Icon }) => {
  // Split the ModelName into two parts based on the first space
  const nameParts = ModelName.split(' ');
  console.log(link)

  return (
    <NavLink to={link} className="relative lg:block lg:overflow-hidden">
      <div className="w-full md:w-64 h-64 bg-defualt-b rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
        <div className="lg:absolute inset-0 flex flex-col justify-between p-4">
          <div className="flex flex-col justify-end items-start space-y-0 uppercase">
            <span className="text-xl md:text-2xl font-bold text-white leading-none mb-[-2px]">{nameParts[0]}</span>
            <span className="text-xl md:text-2xl font-bold text-white leading-none">{nameParts.slice(1).join(' ')}</span>
          </div>

          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Icon className="text-defualt-b text-5xl md:text-6xl bg-white rounded-full p-1" />
          </div>
          <div className="flex justify-start items-start">
            <p className="text-white italic text-center">{desc}</p>
          </div>
        </div>
        <div  className="absolute inset-0 bg-white bg-opacity-95 flex items-center justify-center p-4 opacity-0 transition-opacity duration-400 hover:opacity-100">
          <MdPlayCircleOutline className="text-defualt-b text-6xl" />
        </div>
      </div>
    </NavLink>
  );
};
