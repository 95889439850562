import ReactEcharts from 'echarts-for-react';
import { useEffect } from 'react';

function PieChartSingleInput({ chartData }) {
    // Log the received chartData on component mount or when chartData changes
    useEffect(() => {
        console.log("Received chartData:", chartData);
    }, [chartData]);

    // Ensure the expected data structure exists
    if (!chartData || !chartData.chartValues) {
        console.log("Invalid or undefined chartData:", chartData);
        return <div>No data available</div>;
    }

    const dataObject = chartData.chartValues;
    const legendData = [];
    const dataDisplay = [];

    // Transform data into the format expected by ECharts
    Object.entries(dataObject).forEach(([key, value]) => {
        legendData.push(key);
        dataDisplay.push({ name: key, value });
    });

    console.log("Legend Data:", legendData);
    console.log("Data Display:", dataDisplay);

    return (
        <ReactEcharts
            option={{
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    left: 10,
                    data: legendData
                },
                series: [{
                    name: 'Demographics',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: dataDisplay
                }]
            }}
        />
    );
}

export default PieChartSingleInput;
