import React from 'react';
import { NavLink } from 'react-router-dom';
import { VscRunAll } from 'react-icons/vsc';

export default function Healthcare() {
  document.title = "Healthcare Services";

  const routes = [
    { path: "/stroke", name: "Stroke Detection", desc:"Detech chances of having stroke using our machine learning model." },
    { path: "/skinDisease", name: "Skin Disease ",desc:"Find out what skin disease you have from image upload." },
    { path: "/lungCancer", name: "Lung Cancer ", desc:"Detech chances of having Lung Cancer using our machine learning model."},
  ];

  return (
    

    <div className="flex flex-col justify-center items-center gap-4">
      <h1 className="text-4xl text-defualt-b font-bold flex justify-center items-center pt-5">Healthcare Demos</h1>
     <div className="grid grid-cols-4 gap-4 px-[100px]">
      {routes.map((route, index) => (
        <div key={index} className="border rounded-lg overflow-hidden shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-300 relative bg-white p-5">
        <NavLink to={route.path} className="p-4 text-lg font-semibold text-default hover:text-point-blue transition-colors duration-300">
            <div className="flex justify-between items-center">
                {route.name}
            </div>
            <p className="text-sm text-gray-600 mt-2">
                {route.desc}
            </p>
        </NavLink>
        
    </div>
    
      ))}
    </div>

    </div>
  );
}
