import React,{useEffect} from "react";
import "./App.css";
import axios from 'axios';



import RootLayout from "./component/RootLayout"

import {
  createRoutesFromElements,
  Route,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home from "../src/pages/Home"

import Stroke from "../src/Services/StrokeModule"
import SkinDiseaseModule from "./Services/SkinDiseaseModule";
import LungCancerModule from "./Services/LungCancerModule";
import MachineMaintenanceModule from "./Services/MachineMaintenanceModule";
import OnlineShopperModel from "./Services/OnlineShopperModel";

import Feedback from "./pages/Feedback";
import Services from "./pages/Services";
import Healthcare from "./pages/Healthcare";
import MachineMaintananceCategory from "./pages/MachineMaintananceCategory";
import { Retail } from "./pages/Retail";



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="/stroke" element={<Stroke/>}/> 
      <Route path="/feedback" element={<Feedback/>}/> 
      <Route path='/services' element={<Services/>}/>
      <Route path='/services/healthcare' element={<Healthcare/>}/>
      <Route path='/services/machineMaintenance' element={<MachineMaintananceCategory/>}/>
      <Route path='/services/retail' element={<Retail/>}/>
      <Route path="/skinDisease" element={<SkinDiseaseModule/>}/>
      <Route path="/lungCancer" element={<LungCancerModule/>}/>
      <Route path="/machineMaintenance" element={<MachineMaintenanceModule/>}/>
      <Route path="/onlineShopper" element={<OnlineShopperModel/>}/>
    </Route>
  )
);

function App() {
  const trackVisit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/hit_count`, {
        hitData: { count: "1" }
      });
      console.log('Fetched count:', response.data);
    } catch (error) {
      console.error('Error updating hit count:', error);
    }
  };
  

  useEffect(() => {
    if (!sessionStorage.getItem('visited')) {
      trackVisit();
      sessionStorage.setItem('visited', 'true');
    }
  }, []);
  
  return (
    <>
       <div className='w-full h-screen'>
         <RouterProvider router={router}/> 
        </div>
      

     
    </>
  );
}

export default App;
