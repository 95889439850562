import React, { useState } from 'react';
import { MdOutlineInfo } from 'react-icons/md';

export const ToolTip = ({ text, required, iconSize = '2em' }) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <div className="relative flex items-center">
            <MdOutlineInfo
                style={{ fontSize: iconSize }}
                className="text-slate-500 hover:scale-110 transition-transform duration-150 cursor-pointer"
                onMouseEnter={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)}
            />
            {required && (
                <span className='text-red-500 mx-1'>*</span>
            )}
            {showInfo && (
                <div className='p-2 bg-slate-400 text-white rounded-xl absolute -top-16 mt-[-100%] left-1/2 -translate-x-1/2 shadow-xl w-[400px]'>
                    {text}
                </div>
            )}
        </div>
    );
};
