import {React, useState} from 'react'
import axios from 'axios';

import AlertPopup from './AlertPopup';


export default function ContactForm() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
      });

    const [showAlert, setShowAlert] = useState(false);

      async function SendFormToBackend() {
      
        try {
         
          const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/sendFeedback`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          }
          );
         
          if (response.status === 200) {
           
            
          } else {
            throw new Error('Received a non-200 status code: ' + response.status);
          }
        } catch (error) {
          console.error('Error:', error);
          
          throw error;
        }
      }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      
        // Clear error message when the user starts typing in the field
        setFormErrors({
          ...formErrors,
          [name]: ''
        });
      };
    
    // Function to handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      const errors = validateForm();
    
      if (Object.keys(errors).length === 0) {
        const config = {
          SecureToken: "d209dfc0-49b9-4bdc-9ce3-31bef2794e73",
          To: "aminul.didar@proton.me",
          From: "mantaka35@gmail.com",
          Subject: `${formData.name} has a massage for Model Act app`,
          Body: `Name: ${formData.name} 
                 Email: ${formData.email} 
                 Phone: ${formData.phone} 
                  
                 Message: ${formData.message}`,
        };
    
        if (window.Email) {
          window.Email.send(config)
            .then(() => {
              console.log('Email sent successfully:', formData);
              setShowAlert(true);
              
              setFormData({
                name: '',
                email: '',
                phone: '',
                message: ''
            });
            })
            .catch((error) => {
              console.error('Error sending email:', error);
              // Handle the error, such as displaying an error message to the user
            });
        }
      } else {
        setFormErrors(errors);
      }
    };

    

     const validateForm = () => {
      const errors = {};

      if (!formData.name.trim()) {
        errors.name = 'Name is required';
      }

      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!formData.email.trim()) {
        errors.email = 'Email is required';
      } else if (!emailRegex.test(formData.email.trim())) {
        errors.email = 'Invalid email format';
      }

      if (!formData.message.trim()) {
        errors.message = 'Message is required';
      } else if (formData.message.trim().length < 5) {
        errors.message = 'Message should be at least 25 characters';
      }

      return errors;
  };

      
      

  return (
    <div className='flex flex-col md:flex-row mt-20 md:w-[1200px] w-[400px]'>
        <div className='flex flex-col items-center md:mb-20 md:w-[400px] bg-defualt-b'>
            <h1 className='md:text-6xl text-3xl mb-7 text-white font-bold p-20' >Feedback</h1>
        </div>
        <div>
            <div className='bg-white p-12'>
                <h1 className='md:text-6xl text-3xl font-bold mb-5 pt-5 text-slate-500'>LET <span className="text-defualt-b">US</span> GET IN TOUCH</h1>
                <p className='text-2xl font-medium md:w-[500px] text-slate-500'>Submit what you want to know, and our experts will get back to you very soon.</p>
            </div>
            <form onSubmit={handleSubmit} className='grid grid-cols-2 gap-8 p-5 bg-white h-[500px] mb-20'>
                <div className='flex flex-col'>
                    <label className='md:text-2xl py-4 font-semibold text-defualt-b'>Name <span className="text-red-500">{formErrors.name && '*'}</span></label>
                    <input className='border-b-4 border-0 border-defualt-b' name="name" type="text" value={formData.name}
                     onChange={handleInputChange}>
                    </input>
                    <p className="text-red-500">{formErrors.name}</p>
                    

                    <label className='md:text-2xl py-4 font-semibold text-defualt-b'>Email <span className="text-red-500">{formErrors.email && '*'}</span></label>
                    <input className='border-b-4 border-0 border-defualt-b' name="email" type="text"
                    value={formData.email}
                    onChange={handleInputChange}
                    >
                    </input>
                    <p className="text-red-500">{formErrors.email}</p>

                    <label className='md:text-2xl py-4 font-semibold text-defualt-b'>Phone Number</label>
                    <input className='border-b-4 border-0 border-defualt-b' name="phone" type="tel"
                    value={formData.phone}
                    onChange={handleInputChange}
                    >
                    </input>
                    
                    

                </div>
                <div className='flex flex-col'>
                    <label className='md:text-2xl py-2 font-semibold text-defualt-b'>How can we help you? <span className="text-red-500">{formErrors.message && '*'}</span></label>
                    <textarea
                        className='border-4 rounded-xl border-defualt-b h-[200px] p-2'
                        name="message"
                        placeholder='Enter message here.'
                        value={formData.message} // Change from defaultValue to value
                        onChange={handleInputChange}
                    ></textarea>
                    <p className="text-red-500">{formErrors.message}</p>

                    <input className=' text-white rounded-xl font-semibold bg-orange-400 text-lg p-2 my-5 transition-all hover:scale-105 duration-300 md:w-[250px] flex items-center justify-center hover:cursor-pointer' type="submit" value="SUBMIT"/>
                </div>
                
            </form>
        </div>
        <div className="p-5">
          {showAlert && (
            <AlertPopup
              type="success"
              message="Your operation was successful!"
              onClose={() => setShowAlert(false)}
            />
          )}
        </div>
    </div>
  )
}