import {React, useState, useRef, useEffect} from 'react'
import { ToolTip } from '../component/ToolTip';
import axios from 'axios';
import UniversalChat from '../component/UniversalChat';

import { MdOutlineArrowDropDownCircle } from "react-icons/md";



export default function StrokeModule() {
  document.title = "Stroke Module";

    const [formData, setFormData] = useState({
        gender: -1,
        age: -1,
        hypertension: -1,
        heartdisease: -1,
        married: -1,
        work: -1,
        residence: -1,
        glucose: -1,
        bmi: -1,
        smoke: -1,
      });

      const [formErrors, setFormErrors] = useState({
        gender: '',
        age: '',
        hypertension: '',
        heartdisease: '',
        married: '',
        work: '',
        residence: '',
        glucose: '',
        bmi: '',
        smoke: '',
      });

      const [stroke,setStroke] = useState("")
      
      const [parentIsAnimating, setParentIsAnimating] = useState(false);

      const updateIsAnimating = (value) => {
        
        setParentIsAnimating(value);
      };
      
      const clearAllErrors = () => {
        setFormErrors({
          gender: '',
          age: '',
          hypertension: '',
          heartdisease: '',
          married: '',
          work: '',
          residence: '',
          glucose: '',
          bmi: '',
          smoke: '',
        });
      };
      

      const [inputValid,setValid] = useState("");
      
        async function sendFormDataToBackend(formData) {
          
          try {
            const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/mlapps/getStrokeDetails`, formData);
        
            if (response.status === 200) {
              console.log('Form data submitted successfully:', formData);
              
              const data = response.data;
              if(data === "stroke"){
                const newStrokeValue = `Based on your input and our Machine Learning have determined you have chance of having stroke, take care of your health. *${Date.now()}`;
                setStroke(newStrokeValue);
              } else {
                const newStrokeValue = `Based on your input, you have taken care of your healthy really nicely. Based on our Machine Learning you are healthy and free of worries of stroke. *${Date.now()}`;
                setStroke(newStrokeValue);
              }
              
              
            } else {
              throw new Error('Received a non-200 status code: ' + response.status);
            }
          } catch (error) {
            console.error('Error:', error);
            // Handle the error as needed (e.g., show an error message to the user).
            throw error;
          }
        }
      
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        let newValue;
      
        switch (name) {
          case 'glucose':
          case 'bmi':
            newValue = parseFloat(value);
            break;
          case 'age':
            newValue = parseInt(value, 10); // Parses the value to an integer
            break;
          default:
            newValue = value;
            break;
        }
      
        setFormData({ ...formData, [name]: newValue });
      };
      
    
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(parentIsAnimating){
          return;
        }

        const errors = validateForm(formData);
        

        if (Object.keys(errors).length === 0) {
          try {
            //const updatedFormData = mapYesNoToBinary(formData);
            clearAllErrors();
            setValid("Thank you for giving me the necessary information required. Please wait while I analyze.")
            console.log("Form Data:",formData);
            await sendFormDataToBackend(formData);
      
            
            console.log('Form data sent successfully.');
          } catch (error) {
            console.error('Error sending form data:', error);
            // Handle errors related to form submission
          } finally {
            setValid("");
          }
        } else {
          // Update formErrors state to display error messages
          setFormErrors(errors);
        }
      };
      

      const validateForm = (data) => {
        const errors = {};
    
        // Validate age
        if (data.age <= 0) {
            errors.age = 'Age is required';
        }
    
        // Validate hypertension
        if (data.hypertension === -1) {
            errors.hypertension = 'Hypertension is required';
        }
    
        // Validate heart disease
        if (data.heartdisease === -1) {
            errors.heartdisease = 'Heart Disease is required';
        }
    
        // Validate married
        if (data.married === -1) {
            errors.married = 'Marriage status is required';
        }
    
        // Validate residence type
        if (data.residence === -1) {
            errors.residence = 'Residence type is required';
        }
    
        // Validate BMI
        if (data.bmi < 1) {
            errors.bmi = 'BMI is required';
        }
    
        return errors;
    };
    
    
    const formRef = useRef(null);
    const [formHeight, setFormHeight] = useState(0);
    const [formWidth, setFormWidth] = useState(0);
    
    useEffect(() => {
      if (formRef.current) {
        const height = formRef.current.clientHeight;
        const width = formRef.current.clientWidth;
        setFormHeight(height);
        setFormWidth(width);
      }
    }, []);
    
    const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);

    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    
    const handleBMIChange = (e) => {
      const { name, value } = e.target;
        if (name === 'height') {
          setHeight(parseFloat(value));
        } else if (name === 'weight') {
          setWeight(parseFloat(value));
        }
    };  

    useEffect(() => {
      // Convert height from centimeters to meters
      const heightMeters = height / 100; // 1 meter = 100 centimeters
    
      // Calculate BMI whenever height or weight changes
      const calculatedBMI = (weight / (heightMeters * heightMeters)).toFixed(2);
    
      // Update the BMI in form data
      setFormData((prevFormData) => ({ ...prevFormData, bmi: parseFloat(calculatedBMI) }));
    
    }, [height, weight, setFormData]);
    
    

  return (
    <div className="static bg-white min-h-screen">
      <h1 className='text-4xl text-defualt-b font-bold flex justify-center items-center pt-5'>Stroke Prediction</h1>
      <div className="flex sm:flex-col 2xl:flex-row items-start mx-20 my-5 bg-white rounded-lg shadow-xl sm:w-[1200px] 2xl:w-[1900px]">
        <form
          ref={formRef}
          className="xl:grid grid-cols-2 gap-0 w-auto xl:w-[1200px] p-5 bg-white shadow-2xl border-4 border-defualt-b rounded-xl"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col">
            <div className="flex justify-start items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="age"
              >
                Age
              </label>
              <input
                className="w-[256px] h-[43px] mx-5 font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                id="age"
                name="age"
                type="number"
                placeholder={0}
                onChange={handleInputChange}
              />
              <ToolTip
                required={true}
                text="Please enter your age in years. Age can be an important factor in assessing stroke risk."
              />
            </div>
            {formErrors.age && <p className="text-red-500">{formErrors.age}</p>}

            <div className="flex justify-start items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="hypertension"
              >
                Hypertension
              </label>
              <select
                className="w-[256px] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl mx-5 bg-white"
                id="hypertension"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hypertension: parseInt(e.target.value),
                  })
                }
                value={formData.hypertension}
              >
                <option value={-1}>Choose Hypertension</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
              <ToolTip
                required={true}
                text="Indicate if you have hypertension. Hypertension is a key risk factor for stroke prediction."
              />
            </div>
            {formErrors.hypertension && (
              <p className="text-red-500">{formErrors.hypertension}</p>
            )}

            <div className="flex justify-start items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="heartdisease"
              >
                Heart Disease
              </label>
              <select
                className="w-[256px] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl mx-5 bg-white"
                id="heartdisease"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    heartdisease: parseInt(e.target.value),
                  })
                }
                value={formData.heartdisease}
              >
                <option value={-1}>Choose Heart Disease</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
              <ToolTip
                required={true}
                text="Indicate if you have heart disease."
              />
            </div>
            {formErrors.heartdisease && (
              <p className="text-red-500">{formErrors.heartdisease}</p>
            )}

            <div className="flex justify-start items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="married"
              >
                Marriage
              </label>
              <select
                className="w-[256px] mx-5 font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                id="married"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    married: parseInt(e.target.value),
                  })
                }
                value={formData.married}
              >
                <option value={-1}>Choose Marriage</option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
              <ToolTip
                required={true}
                text="Indicate if you are married."
              />
            </div>
            {formErrors.married && (
              <p className="text-red-500">{formErrors.married}</p>
            )}

            <div className="flex justify-start items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="residence"
              >
                Residence Type
              </label>
              <select
                className="w-[256px] mx-5 font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                id="residence"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    residence: parseInt(e.target.value),
                  })
                }
                value={formData.residence}
              >
                <option value={-1}>Choose Residence Type</option>
                <option value={1}>Urban</option>
                <option value={2}>Rural</option>
              </select>
              <ToolTip
                required={true}
                text="Residence type may influence certain health factors, aiding in stroke risk assessment."
              />
            </div>
            {formErrors.residence && (
              <p className="text-red-500">{formErrors.residence}</p>
            )}

            <div className="flex flex-col">
              <div className="flex justify-start items-center">
                <label
                  className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                  htmlFor="bmi"
                >
                  BMI
                </label>
                <input
                  className="w-[256px] h-[43] mx-5 font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                  id="bmi"
                  name="bmi"
                  type="number"
                  placeholder={0}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      bmi: parseFloat(e.target.value),
                    })
                  }
                  value={formData.bmi}
                />
                <ToolTip
                  required={true}
                  text="Please input your Body Mass Index (BMI)"
                />
              </div>
            </div>
            {formErrors.bmi && <p className="text-red-500">{formErrors.bmi}</p>}
          </div>

          <div>
            
            <div className="flex justify-between items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500 mr-1"
                htmlFor="glucose"
              >
                Average Glucose Level
              </label>
              <input
                className="w-[256px] h-[43px] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                id="glucose"
                name="glucose"
                type="number"
                onChange={handleInputChange}
              />
              <ToolTip text="Enter your fasting blood glucose level. Normal range is 70-100 mg/dL." />
            </div>

            <div className="flex justify-between items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="gender"
              >
                Gender
              </label>
              <select
                className="w-[256px] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                id="gender"
                onChange={(e) =>
                  setFormData({ ...formData, gender: parseInt(e.target.value) })
                }
                value={formData.gender}
              >
                <option value={-1}>Select Gender</option>
                <option value={1}>Male</option>
                <option value={2}>Female</option>
                <option value={3}>Other</option>
              </select>
              <ToolTip text="What is your gender?" />
            </div>

            <div className="flex justify-between items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="work"
              >
                Work Type
              </label>
              <select
                className="w-[256px] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                id="work"
                onChange={(e) =>
                  setFormData({ ...formData, work: parseInt(e.target.value) })
                }
                value={formData.work}
              >
                <option value={-1}>Choose Work Type</option>
                <option value={1}>Children</option>
                <option value={2}>Government Job</option>
                <option value={3}>Never worked</option>
                <option value={4}>Private</option>
                <option value={5}>Self Employed</option>
              </select>
              <ToolTip text="Your work type will help us more accurately predict." />
            </div>

            <div className="flex justify-between  items-center">
              <label
                className="w-[182px] font-bold text-2xl py-5 text-slate-500"
                htmlFor="smoke"
              >
                Smoking
              </label>
              <select
                className="w-[256px] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                id="smoke"
                onChange={(e) =>
                  setFormData({ ...formData, smoke: parseInt(e.target.value) })
                }
                value={formData.smoke}
              >
                <option value={-1}>Do you smoke?</option>
                <option value={1}>I smoked before</option>
                <option value={2}>I never smoked</option>
                <option value={3}>I smoke</option>
                <option value={4}>Unknown</option>
              </select>
              <ToolTip
                required={false}
                text="Do you smoke?"
              />
            </div>
            <div>
              {!showAdditionalInputs && (
                <button
                  className="flex font-bold text-2xl py-5 text-slate-500 pt-16"
                  onClick={() => setShowAdditionalInputs(true)}
                >
                  Calculate BMI
                  <MdOutlineArrowDropDownCircle className="ml-5 text-4xl transition-transform duration-200 hover:scale-110 text-defualt-b" />
                </button>
              )}

              {showAdditionalInputs && (
                <div className="flex flex-col border-2 border-defualt-b p-5 rounded-lg">
                  <div className=''>
                  <div className='flex flex-col'>
                  <label
                    className="w-[182px] font-bold text-2xl py-1 text-slate-500"
                    htmlFor="height"
                  >
                    Height(cm)
                  </label>
                  <input
                    className="w-[256px] mr-2 h-[43] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                    type="number"
                    placeholder="0"
                    name="height"
                    onChange={handleBMIChange}
                    value={height}
                  />
                  </div>
                  <div className='flex flex-col'>
                  <label
                    className="w-[182px] font-bold text-2xl py-1 text-slate-500"
                    htmlFor="weight"
                  >
                    Weight(kg)
                  </label>
                  <input
                    className="w-[256px] h-[43] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-xl my-5 bg-white"
                    type="number"
                    placeholder="0"
                    name="weight"
                    onChange={handleBMIChange}
                    value={weight}
                  />
                  </div>
                  </div>
                  <button onClick={() => setShowAdditionalInputs(false)}>
                    <MdOutlineArrowDropDownCircle className="text-4xl text-defualt-b rotate-180 transition-transform duration-200 hover:scale-110" />
                  </button>
                </div>
              )}
            </div>
            <button
            type="submit"
            className="py-2 px-4 w-[500px] mt-4 bg-defualt-b hover:bg-point-blue hover:scale-105 text-lg font-bold transition-all duration-200 text-white rounded-md"
          >
            Submit
          </button>
          </div>

        </form>
        <UniversalChat height={formHeight} updateIsAnimating={updateIsAnimating} receviedText={inputValid}
        error={formErrors} output={stroke} width={formWidth}/>
        {/* <StrokeChat
          height={formHeight}
          updateIsAnimating={updateIsAnimating}
          success={inputValid}
          Errors={formErrors}
          Stroke={stroke}
        /> */}
      </div>
    </div>
  );
}