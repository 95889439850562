// export function processResponseData(response) {
//     const text = response.text;
//     console.log(text)
//     // Regular expression to find base64 string inside <img> tag
//     const base64Pattern = /<img>(.*?)<\\img>/s;

//     // Extract base64 string using regex
//     const match = text.match(base64Pattern);

//     if (match) {
//       const base64String = match[1];
//       console.log("base64Stringo:", base64String);
     
//     } else {
//       console.error("Base64 string not found.");
//     }

   
// }

import { CodeSnippet } from "../component/CodeSnippet";

export function processResponseData(response) {
  const responseText = response.text;
  const imgRegex = /<img>(.*?)<\\img>/s;
  const codeStartRegex = /\nUse the below function for predictions:/;

  // Split the response into parts, capturing base64 encoded images as separate array items
  const parts = responseText.split(imgRegex);
  const match = responseText.match(imgRegex);
  console.log(parts);

  let img = "";
  if (match && match[1]) {
      img = match[1].trim();
  }

  // Find the start index of the code snippet
  const codeStartIndex = responseText.search(codeStartRegex);
  let codeSnippet = "";
  if (codeStartIndex !== -1) {
      codeSnippet = responseText.substring(codeStartIndex + codeStartRegex.source.length);
      parts[parts.length - 1] = parts[parts.length - 1].substring(0, parts[parts.length - 1].indexOf(codeSnippet)); // Remove code snippet from the last part
  }

  return (
      <div>
          {parts.map((part, index) => {
              part = part.trim(); // Trim each part to clean up any excess whitespace
              if (img && part === img) {
                  // Render image for the base64 encoded string if it exists
                  return <img key={index} src={`data:image/png;base64,${img}`} alt="Embedded Content" style={{ maxWidth: '100%' }} />;
              } else if (part.length > 0) { // Ensure empty strings are not processed further
                  // Split text by new lines to apply styles to sections
                  return part.split('\n').map((section, idx) => {
                      section = section.trim(); // Further trimming each section
                      if (section.startsWith('###')) {
                          // Apply styles to header sections
                          return (
                              <p key={`${index}-${idx}`} className="font-bold text-2xl text-defualt-b drop-shadow-md my-2">
                                  {section.substring(3)}
                              </p>
                          );
                      } else {
                          // Normal text rendering
                          return <p className="text-stone-800 text-lg pl-2" key={`${index}-${idx}`}>{section}</p>;
                      }
                  });
              }
          })}
          {codeSnippet && (
              <CodeSnippet code={codeSnippet} />
          )}
      </div>
  );
}




export const  downloadFile = async (filename) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DJANGOAPI}/mlapps/universalModelsDownloads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: `${filename}`, 
        }),
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}`); 
      document.body.appendChild(link)
;
      link.click();
      link.parentNode.removeChild(link)
;
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
