import React from 'react'
import CategoryCard from '../component/CategoryCard'



export default function Services() {
  document.title = "Services";
  const categories = [
    // {
    //   categoryTitle: "Healthcare",
    //   description: "Explore our wide range of Machine Learning models to help you with detecting different health care problems",
    //   index: 0,
    //   buttonLink: "/services/healthcare"
    // },
    // {
    //   categoryTitle: "Machine Maintenance",
    //   description: "Explore our wide range of Machine Learning models to help you with detecting different health care problems",
    //   index: 1,
    //   buttonLink: "/services/machineMaintenance"
    // },
    {
      categoryTitle: "Retail",
      description: "Use our tailored models to improve your retail sales predictions.",
      index: 2,
      buttonLink: "/services/retail"
    }
  ];

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 px-2 sm:pl-[100px] items-center justify-center gap-4'>
        {/* <CategoryCard
          categoryTitle="Healthcare"
          description="Explore our wide range of Machine Learning models to help you with detecting different health care problems"
          index={0}
          buttonLink="/services/healthcare"
        />
        <CategoryCard
          categoryTitle="Machine Maintenance"
          description="Explore our wide range of Machine Learning models to help you with maintaining your machines"
          index={1}
          buttonLink="/services/machineMaintenance"
        /> */}
      
      {categories.map((category, index) => (
        <CategoryCard
          key={index}
          categoryTitle={category.categoryTitle}
          description={category.description}
          index={category.index}
          buttonLink={category.buttonLink}
        />
      ))}
    </div>
  )
}
