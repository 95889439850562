import React, { useState } from 'react'
import axios from 'axios';

import UniversalChat from '../component/UniversalChat';

export default function LungCancerModule() {
  document.title = "Lung Cancer Module";
  
  const symptoms = [
    { name: 'Air_Pollution', start: 0, end: 8 },
    { name: 'Alcohol_use', start: 0, end: 8 },
    { name: 'Dust_Allergy', start: 0, end: 8 },
    { name: 'OccuPational_Hazards', start: 0, end: 8 },
    { name: 'Balanced_Diet', start: 0, end: 7 },
    { name: 'Obesity', start: 0, end: 7 },
    { name: 'Smoking', start: 0, end: 8 },
    { name: 'Passive_Smoker', start: 0, end: 8 },
    { name: 'Coughing_of_Blood', start: 0, end: 9 },
    { name: 'Fatigue', start: 0, end: 9 },
    { name: 'Shortness_of_Breath', start: 0, end: 9 },
    { name: 'Wheezing', start: 0, end: 8 },
    { name: 'Swallowing_Difficulty', start: 0, end: 8 },
    { name: 'Clubbing_of_Finger_Nails', start: 0, end: 9 },
    { name: 'Snoring', start: 0, end: 7 },
  ];

  const [values, setValues] = useState(
    symptoms.reduce((acc, curr) => {
      acc[curr.name] = Math.round((curr.start + curr.end) / 2); 
      return acc;
    }, {})
  );

  const [lungDanger,setLungDanger] = useState("");
  const [success,setSuccess] = useState("");

  const handleSliderChange = (name, newValue) => {
    setValues({
      ...values,
      [name]: Number(newValue), 
    });
  };

  async function sendLungDetailsDataToBackend(formData) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/mlapps/getLungDetails`, formData);

      if (response.status === 200) {
        console.log('Form data submitted successfully:', formData);

        const data = response.data;
          if (data === "low") {
            setSuccess("Based on our assessment, you have a low risk of developing lung cancer. It's important to continue maintaining a healthy lifestyle and regular check-ups.");
          } else if (data === "medium") {
            setSuccess("Your results indicate a medium risk of lung cancer. We recommend further assessments and consultations with a healthcare provider to discuss preventive measures.");
          } else if (data === "high") {
            setSuccess("You are at a high risk of lung cancer. Immediate consultation with a healthcare professional is strongly advised to discuss diagnostic and preventive steps.");
          }
        

      } else {
        throw new Error('Received a non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
      throw error;
    }
  }

  // Submit handler
  const handleSubmit = (event) => {
    event.preventDefault();
    if(parentIsAnimating){
      return;
    }
    setLungDanger("Thank you for your input. Please wait while I analyze.")
    sendLungDetailsDataToBackend(values);
    console.log(values);
  };

  const [parentIsAnimating, setParentIsAnimating] = useState(false);

    const updateIsAnimating = (value) => {
      
      setParentIsAnimating(value);
    };
  
  return (
    <div className="static bg-white min-h-screen">
      <h1 className="text-4xl px-[50px] md:px-0 text-defualt-b font-bold flex justify-center items-center pt-5">
      Lung Cancer Prediction
      </h1>
      <div className="xl:flex p-10">
        <form
          className="xl:grid grid-cols-2 gap-x-2 gap-y-2 w-[340px] md:w-[650px] p-5 bg-white shadow-2xl border-4 border-default-b rounded-xl"
          onSubmit={handleSubmit}
        >
          {symptoms.map((param) => (
            <div key={param.name} className="mb-2 ml-2 w-auto xl:w-[320px] flex flex-col">
            <label className="font-bold text-xl py-2 text-slate-500" htmlFor={param.name}>
              {param.name.replace(/_/g, ' ')}: {values[param.name]}
            </label>
            <input
              id={param.name}
              type="range"
              min={param.start}
              max={param.end}
              value={values[param.name]}
              onChange={(e) => handleSliderChange(param.name, e.target.value)}
              className="rangeSlider shadow-md rounded-lg border-2 border-cyan-500 h-[10px] w-auto xl:w-[250px]"
            />
          </div>
          
          ))}
          <button
            type="submit"
            className="py-2 px-4  mt-4 bg-defualt-b hover:bg-point-blue hover:scale-105 text-lg font-bold transition-all duration-200 text-white rounded-md"
          >
            Submit
          </button>
        </form>
        <div>
          {/* <h1 className='text-2xl text-defualt-b font-bold p-5'>Results:</h1>
          <span className='text-xl text-defualt-b font-semibold p-5'>{lungDanger}</span> */}
          <UniversalChat receviedText={lungDanger} output={success} updateIsAnimating={updateIsAnimating} height={"700px"} error={""} width={"600px"}/>
        </div>
      </div>
    </div>
  );
}
