import React, { useState } from 'react';
import axios from 'axios';
import { FaCheck } from 'react-icons/fa';



export default function UniversalModel({ columns, fname, ftype, getUniversalResponse }) {
  const [modelSpecifications, setModelSpecifications] = useState({});
  const [selectedModel, setSelectedModel] = useState('');
  const [targetVariable, setTargetVariable] = useState('');

  const ChartSelector = (event) => {
    setSelectedModel(event.target.value);
    if (!['regression', 'classification','recommender'].includes(event.target.value)) {
      setTargetVariable(''); // Reset target variable if not regression or classification
      setModelSpecifications({}); // Reset model specifications when changing model types
    }
  };

  const handleCheckboxChange = (variable) => {
    setModelSpecifications(prevSpecs => ({
      ...prevSpecs,
      [variable]: !prevSpecs[variable],
    }));
  };

  const isSelectedCheckbox = Object.values(modelSpecifications).some(value => value);
  const isFormReady = selectedModel && isSelectedCheckbox && (['clustering'].includes(selectedModel) || targetVariable);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const columnsObject = modelSpecifications;
    const finalData = {
      selectedModel,
      variables: {
        features: Object.keys(columnsObject).filter(key => columnsObject[key]),
        targetVariable: targetVariable
      },
      file: `${fname}.${ftype}`
    };
    

    console.log(finalData);
    await sendFormDataToBackend(finalData);
  };

 

  async function sendFormDataToBackend(formData) {
    getUniversalResponse("loading")
    try {
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/mlapps/universalModels`, formData);
      // console.log('Form data submitted successfully:', formData);
      console.log('Form data submitted successfully:', response.data);
      getUniversalResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSelectChange = (event) => {
    setTargetVariable(event.target.value);
    setModelSpecifications({}); // Reset variable selections when target variable changes
  };

  return (
    <div>
      <h1 className='text-2xl text-defualt-b font-bold flex justify-start pt-5 ' >Select Model Type</h1>
      <form onSubmit={handleFormSubmit}>
        <select
          onChange={ChartSelector}
          value={selectedModel}
          className="bg-sky-500 p-[14px] border rounded-md text-medium text-white hover:bg-cyan-500 cursor-pointer my-2"
        >
          <option value="">Please choose a Model Type</option>
          <option value="classification">Classification</option>
          <option value="clustering">Clustering</option>
          <option value="regression">Regression</option>
          <option value="recommender">Recommender - Preference</option>
        </select>
        {['regression', 'classification','recommender'].includes(selectedModel) && (
          <div>
            <label htmlFor="columnSelect" className='text-2xl text-defualt-b font-bold flex justify-start items-center py-3'>Select Target Variable:</label>
            <select
              id="columnSelect"
              className="bg-sky-500 p-[14px] border rounded-md text-medium text-white hover:bg-cyan-500 cursor-pointer my-2"
              value={targetVariable}
              onChange={handleSelectChange}
              disabled={!columns.length}
            >
              <option value="">Select a column</option>
              {columns.map((column, index) => (
                <option key={index} value={column}>{column}</option>
              ))}
            </select>
          </div>
        )}
        <h1 className='text-2xl text-defualt-b font-bold flex justify-center items-center pt-5'>Select Input Variables</h1>
        <div className={`border-2 border-default-b shadow-lg rounded-md mt-5 p-1 h-[300px] overflow-y-auto w-auto mr-5 ${!targetVariable && ['regression', 'classification','recommender'].includes(selectedModel) ? 'opacity-50' : ''}`}>
          {columns.filter(variable => variable !== targetVariable).map((variable, index) => (
            <div key={index}>
              <label className='text-xl text-default-b flex'>
                <input
                  type="checkbox"
                  name={variable}
                  checked={!!modelSpecifications[variable]}
                  onChange={() => handleCheckboxChange(variable)}
                  disabled={!targetVariable && ['regression', 'classification','recommender'].includes(selectedModel)}
                  className='mr-2 h-0 w-0 opacity-0'
                />
                <span className='h-[23px] w-[23px] rounded-full border-2 border-slate-400 flex justify-center items-center m-1'>
                  {modelSpecifications[variable] && <FaCheck className='text-defualt-b text-sm'/>}
                </span>
                {variable}
              </label>
            </div>
          ))}
        </div>
        <button 
          type="submit"
          className={`btn my-5 ${isFormReady ? 'bg-blue-500 hover:bg-blue-700' : 'bg-blue-300 cursor-not-allowed'}`}
          disabled={!isFormReady}
        >
          Submit Model Selection
        </button>
      </form>
    </div>
  );
}
