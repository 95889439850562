import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";

import ModelActLogo from "../Assets/ModelActLogoWhite.png"



const Navbar = () => {
  // const links = [
  //   { to: "/stroke", text: "Stroke Detection" },
  //   { to: "/skinDisease", text: "Skin Disease Detection" },
  //   { to: "/lungCancer", text: "Lung Cancer Detection" },
  //   { to:"/machineMaintenance", text: "Machine Maintenance"}
  // ];

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (


    <div className="shadow-lg bg-cyan-500 w-full sticky top-0 left-0 z-10 mb-6">
      <div className="flex items-center justify-between xl:px-10">
        <div className="ml-3 text-white">
            <a href="/" className="flex items-center space-x-3"> 
                <img src={ModelActLogo} className="h-8 w-14" alt=""/> 
                <div>
                    <h3 className="flex text-3xl pt-2">
                        Model Act
                        <span className="text-sm pt-4 block">Unleashing AI Through Action</span>
                    </h3>
                </div>
            </a>
        </div>
        <button className="md:hidden mr-3 text-white text-3xl" onClick={toggleNavbar}>
              {isNavOpen ? <RiCloseLine /> : <RiMenu3Line />}
          </button>
          

        <div className="">
          {/*<div>
            <li className="relative mx-5 py-2 list-none group ">
              <span className="text-slate-500 font-semibold rounded-lg cursor-pointer py-3 pl-3 bg-white flex">
                Services
                <RiArrowDropDownLine className="text-2xl px-1" />
              </span>
              <div className="hidden group-hover:block">
                <ul className="absolute top-full left-0 bg-white py-2 rounded-md shadow-lg font-bold">
                  {links.map((link, index) => (
                    <li key={index}>
                      <NavLink
                        className="block px-4 py-2 text-slate-500 hover:bg-gray-200"
                        to={link.to}
                      >
                        {link.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </div> */}
          
          

          <ul className={`flex flex-col sm:flex-row items-center bg-cyan-500 absolute sm:relative w-full pl-0 transition-all duration-500 ease-in-out ${isNavOpen ? "top-[90px] left-0" : "left-0 top-[-180px] sm:top-0"}`}>
            <li className="text-white font-bold text-lg my-2 px-2 sm:mx-2">
                <NavLink
                    to="/feedback"
                    className="block mr-3 p-3 rounded-lg transform transition-all duration-150 hover:scale-105 hover:bg-white hover:text-slate-500"
                    onClick={() => setIsNavOpen(false)}
                >
                    Feedback
                </NavLink>
            </li>
            <li className="text-white font-bold text-lg my-2 py-2 px-2 sm:mx-2">
                <NavLink
                    to="/services/retail"
                    className="block p-3 rounded-lg transform transition-all duration-150 hover:scale-105 hover:bg-white hover:text-slate-500"
                    onClick={() => setIsNavOpen(false)}
                >
                    Demos
                </NavLink>
            </li>
        </ul>

        </div>

        </div>
      
    </div>
  );
}

export default Navbar;
