import { React, useState, useEffect } from "react";
import axios from "axios";

// import PieChart from "../VisActComponents/PieChart";
import LineChart from "../VisActComponents/LineChart";
// import BarChart from "../VisActComponents/BarChart";
import ShowData from "../VisActComponents/ShowData";
import GetChartProperties from "../VisActComponents/GetChartProperties";
import FileUpload from "../VisActComponents/FileUpload";
import WordCloud from "../VisActComponents/WordCloud";
import DownloadConvFile from "../VisActComponents/DownloadConvFile";
import ScatterChart from "../VisActComponents/ScatterChart";
import MlModule from "../MlModule";
import UniversalModel from "../component/UniversalModel";
import PieChartSingleInput from "../VisActComponents/PieChartSingleInput";
import BarChartSingleInput from "../VisActComponents/BarChartSingleInput";

import {Search} from "../VisActComponents/Search";
import { processResponseData } from "../GeneralFunctions/UniversalModelOutputExtraction";
import { downloadFile } from "../GeneralFunctions/UniversalModelOutputExtraction";

import Lottie from 'react-lottie';
import loadingGif from "../Assets/CategoryGifs/loadingGif.json";

export default function Home() {
  document.title = "Model Act";
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [rowValues, setValues] = useState([]);
  // Track file type
  const [fileType, setfileType] = useState("test");
  const [fileName, setfileName] = useState("");


  const [components, setComponents] = useState([false]);

  const [x_axis, setXAxis] = useState("");
  const [y_axis, setYAxis] = useState("");
  const [message, setMessage] = useState("");

  const [dataMode,setDataMode] = useState("start");
  const [chartValues,setChartValues] = useState([]);
  
  const [universalResponse,setUniversalResponse] = useState("");
  const [universalShow,setUniversalShow] = useState(false);

  const [fileStats, setFileStats] = useState([
    { title: "Number of Rows", value: 100 },
    { title: "Number of Columns", value: 10 },
    { title: "Categorical Columns", value: 4 },
    { title: "Continuous Columns", value: 6 },
    { title: "Missing Values", value: 25 },
  ]);

  const getFileInput = (Props) => {
    // console.log(Props);

    setValues(Props.rowValues);
    setParsedData(Props.parsedData);
    setTableRows(Props.tableRows);
    setfileType(Props.fileType);
    setComponents(Props.components);
    setMessage(Props.message);
    setfileName(Props.fileName);
  };



  const getUserInput = (Props) => {
    console.log(Props);
    setXAxis(Props.x_axis);
    setYAxis(Props.y_axis);
    setComponents(Props.chatType);
    setMessage(Props.message);

  };

  const getUniversalInput = (response) => {
    // console.log("Received response:", response);
    setUniversalResponse(response);
    setUniversalShow(true);  
  }


  useEffect(() => {
    if (["Pie Chart", "Bar Chart", "Word Cloud"].includes(components[0]) && x_axis !== "" && y_axis==="") {
      
      //  setChartValues(
      //   {"game": 1.0, "world": 0.8144329896907216, "new": 0.7809278350515464, 
      //   "one": 0.4896907216494845, "player": 0.4845360824742268
      //   });
      sendChartDataToBackend();
    }
  }, [components[0], x_axis]);

  async function sendChartDataToBackend() {
    const chartData = {
      chartData: {
        fileName: `${fileName}.${fileType}`,
        chartType: components[0],
        columnNames: [x_axis]
      }
    };
    
    try {
  
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/charts`, chartData);

      console.log('Chart data submitted successfully:', chartData);

      if (response.status === 200) {
        //console.log('Chart response:', response.data);
        setChartValues(response.data);
      } else {
        throw new Error('Received a non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      
      // Additional error handling logic here if necessary
    }
  }

  //Loading Univerresponse
  const renderContent = () => {
    if (typeof universalResponse === "string" && universalResponse.includes("loading")) {
      if (loadingGif) {  // Check if loadingGif is not undefined
        return (
          <>
          <Lottie 
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingGif,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={160}
            width={160}
            isStopped={false}
            isPaused={false}
          />
          <h1 className="text-defualt-b text-2xl flex justify-center items-center">Training AI Model...</h1>
          </>
        );
      } else {
        return <p>Loading animation data not available.</p>;
      }
    } else {
      return processResponseData(universalResponse);
    }
  };


  return (
    <div>
      <div className="flex justify-center items-center">
        {/* <div className="bg-white rounded-lg shadow-md w-[250px] mx-12 mt-[130px] overflow-hidden">
          <h2 className="text-white font-thin text-lg bg-cyan-500 w-full text-center p-2 mb-0">
            File Statistics
          </h2>
          <table className="min-w-full">
            <tbody>
              {fileStats.map((stat, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                  }`}
                >
                  <td className="text-sm font-semibold text-start pl-5 py-2">
                    {stat.title}
                  </td>
                  <td className="text-sm font-bold text-center py-2 pr-5">
                    {stat.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

        <div className="flex-shrink-0">
          <FileUpload onFileLoad={getFileInput} />
        </div>
      </div>

      {/*{tableRows.length > 0 && (*/}
      <div>
        <div>
          {
            <h4 className="text-center text-sky-500 font-bold text-2xl mt-6">
              {message}
            </h4>
          }

          {(() => {
            //console.log(parsedData,components[0]);

            if (
              parsedData.length !== 0 &&
              x_axis !== "" &&
              y_axis !== "" &&
              components[0] === "ML Module"
            ) {
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              //console.log(parsedData);
              return (
                <MlModule
                  data={{ parsedData, x_axis, y_axis, tableRows, fileType }}
                />
              );
            }
          })()}

          {(() => {
            //console.log(parsedData,components[0]);

            if (
              parsedData.length !== 0 &&
              x_axis !== "" &&
              y_axis !== "" &&
              components[0] === "Line Chart"
            ) {
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              //console.log(parsedData);
              return (
                <LineChart
                  data={{ parsedData, x_axis, y_axis, tableRows, fileType }}
                />
              );
            }
          })()}

          {/*Block: Pie Chart 

          {(() => {
            //console.log(parsedData,components[0]);

            if (parsedData.length !== 0 && components[0] === "Pie Chart") {
              //&& x_axis !=='' && y_axis !==''
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              console.log(tableRows, rowValues);
              return (
                <PieChart
                  chartData={{
                    x_axis,
                    y_axis,
                    tableRows,
                    rowValues,
                    parsedData,
                  }}
                />
              );
            }
          })()} */}

          {/*Block: Single Input Pie Chart */}

          {(() => {
            //console.log(parsedData,components[0]);

            if (parsedData.length !== 0 && components[0] === "Pie Chart" && x_axis !=='') {
              //&& x_axis !=='' && y_axis !==''
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              console.log(tableRows, rowValues);
              return (
                <PieChartSingleInput
                  chartData={{
                    chartValues
                  }}
                />
              );
            }
          })()}

          {/*Block: Bar Chart 

          {(() => {
            //console.log(parsedData,components[0]);

            if (parsedData.length !== 0 && components[0] === "Bar Chart") {
              //&& x_axis !=='' && y_axis !==''
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              console.log(tableRows, rowValues);
              return (
                <BarChart
                  chartData={{
                    x_axis,
                    y_axis,
                    tableRows,
                    rowValues,
                    parsedData,
                  }}
                />
              );
            }
          })()}*/}
          
          {/*Block: Single Input Bar Chart */}

          {(() => {
            //console.log(parsedData,components[0]);

            if (parsedData.length !== 0 && components[0] === "Bar Chart" && x_axis !=='') {
              //sendChartDataToBackend();
              //&& x_axis !=='' && y_axis !==''
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              console.log(tableRows, rowValues);
              return (
                <BarChartSingleInput
                chartData={{
                  chartValues
                }}
                />
              );
            }
          })()}

          {(() => {
            //console.log(parsedData,components[0]);

            if (
              parsedData.length !== 0 &&
              x_axis !== "" &&
              y_axis !== "" &&
              components[0] === "Scatter Chart"
            ) {
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              //console.log(parsedData);
              return (
                <ScatterChart
                  data={{ parsedData, x_axis, y_axis, tableRows, fileType }}
                />
              );
            }
          })()}

          {(() => {
            // console.log("chartValues311: ", chartValues);

            // console.log("ParsedData Length:", parsedData.length);
            // console.log("Component[0]:", components[0]);
            // console.log("x_axis:", x_axis);
            // console.log("Chart Values:", chartValues);

            if (parsedData.length !== 0 && components[0] === "Word Cloud" && x_axis !== "" ) {
              //console.lognpm start('Inside line chart_1');
              //setComponents([false]);
              //console.log('WordCloud printing..', chartValues);
              return <WordCloud data={{chartValues}} />;
            }
          })()}
        </div>
        {tableRows.length > 0 && (
          <div className="flex flex-col sm:flex-row gap-4 mt-4 p-2">
            <div className="flex-1 sm:w-2/3">
           {universalResponse !== "" && (
             <div className="flex flex-col sm:flex-row gap-2 ml-3">
              <button 
                  onClick={() => setUniversalShow(false)} 
                  className={`btn-tab uppercase font-semibold ${!universalShow ? 'btn-tab-active' : ''}`}
              >
                  Data Table
              </button>
              <button 
                  onClick={() => setUniversalShow(true)} 
                  className={`btn-tab uppercase font-semibold ${universalShow ? 'btn-tab-active' : ''}`}
              >
                  Universal Output
              </button>
             </div>
           )}

            {universalShow ? (
                universalResponse ? (
                    <div>
                    <div className="bg-[#F2F2F2] ml-3 rounded-b-lg rounded-tr-lg p-2 h-[800px] overflow-y-auto overflow-x-auto shadow-lg mb-3" style={{ whiteSpace: 'pre-wrap'}}>
                        <h2 className="px-[50px] xl:px-0 text-4xl text-defualt-b font-bold flex justify-center items-center pt-5" >Universal Model Output</h2>
                      {/* {processResponseData(universalResponse)} * Display response data */}
                      {renderContent()}
                    </div>
                    <div className="flex flex-1">
                    <button onClick={() => downloadFile(universalResponse.savedModels)} className="btn mr-2">Download Model</button>
                    <button onClick={() => downloadFile(universalResponse.savedFiles)} className="btn">Download Files</button>
                    </div>
                    </div>
                ) : (
                    <p>No response available. Please submit your model data.</p>
                )
            ) : (
                  <>
                    {/* <Search fileName={fileName} fileType={fileType}/> */}
                    <ShowData tableData={{ tableRows, rowValues,fileName,fileType }} getUserInput={getUserInput}/>
                  </>
           
            )}
             
            </div>
            <div className=" flex flex-col items-start">
              <div className="mb-1 xl:mb-4 mr-3">
                {(() => {
                  if (parsedData.length !== 0) {
                    return (
                      <DownloadConvFile
                        data={{ parsedData, tableRows, fileType, fileName }}
                      />
                    );
                  }
                })()}
              </div>
              <div className="xl:flex flex-row gap-3">
              <button onClick={() => setDataMode("chart")} className="btn flex-1 mr-2 mb-2">
                Data Visualization
              </button>
              <button onClick={() => setDataMode("model")} className="btn flex-1">
                Universal Model
              </button>
              </div>
              {dataMode==="chart" &&( <GetChartProperties onSubmit={{ getUserInput, tableRows }} />)}
              {dataMode=== "model" && ( <UniversalModel fname={fileName} ftype={fileType} columns={tableRows} getUniversalResponse={getUniversalInput}/>)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}