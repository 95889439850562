import ReactEcharts from 'echarts-for-react';
import { useEffect } from 'react';

function BarChartSingleInput({ chartData }) {
  // Extract keys for xAxis and values for series data from the chartData object
//   useEffect(() => {
//     console.log("Received chartData:", chartData);
//     console.log(Object.keys(chartData))
// }, [chartData]);
    

const dataObject = chartData.chartValues;

// Extract keys for xAxis and values for series data from the dataObject
const legendData = Object.keys(dataObject); 
const dataDisplay = Object.values(dataObject); 

  // Prepare the chart option configuration
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { // Use a line pointer
        type: 'line'
      }
    },
    xAxis: {
      type: 'category',
      data: legendData,
      axisLabel: {
        interval: 0,
        rotate: 30 // Rotate labels for better visibility if many categories
      }
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      data: dataDisplay,
      type: 'bar',
      barWidth: '60%', // Customize bar width here
      itemStyle: {
        color: '#5470C6' // Customize bar color here
      }
    }]
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: '350px', width: '100%' }} // Adjustable size
    />
  );
}

export default BarChartSingleInput;
