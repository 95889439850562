import React, { useEffect, useState, useRef } from 'react';
import botIcon from "../Assets/robotFace.png";

function UniversalChat({ receviedText, output, error, updateIsAnimating,width, height }) {
  const [messages, setMessages] = useState([
    'Welcome! Please provide the necessary input, and I will try my best to aid you in your query',
  ]);

  const [isAnimating, setIsAnimating] = useState(false);
  const [queue, setQueue] = useState([]);
  const chatEndRef = useRef(null);

  const appendToQueue = (item) => {
    setQueue(prevQueue => [...prevQueue, item]);
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (receviedText) {
      appendToQueue(receviedText);
    }
  }, [receviedText]);
  

  useEffect(() => {
    let hasErrors = false;
  
    if (typeof error === 'string') {
      hasErrors = error.trim() !== '';
    }
    
    else if (typeof error === 'object' && Object.keys(error).length > 0) {
      hasErrors = Object.values(error).some(errorMessage => typeof errorMessage === 'string' && errorMessage.trim() !== '');
    }
  
    if (hasErrors) {
      appendToQueue('There are a few necessary pieces of information required. Please ensure you have filled out the form correctly.');
    }
  }, [error]);
  
  
  

  useEffect(() => {
    if (output) {
      let resultString = "";

      
      if (typeof output === 'string') {
        if (output.includes('*')) {
          
          resultString = output.split('*')[0];
        } else {
    
          resultString = output;
        }
      } else if (React.isValidElement(output)) {
        // If output is a React element (e.g., <PieChart />)
        console.log(output)
        console.log(typeof(output))
        appendToQueue(output);
        return;
      } else if (Array.isArray(output) && output.length > 0) {
        // If output is an array of objects or strings, format it accordingly
        resultString = output.map(item => {
          if (typeof item === 'string') {
            return item;
          } else if (typeof item === 'object') {
            return `${item.label}: ${item.value}`;
          }
          return '';
        }).join(', ');
      }

      if (resultString) {
        appendToQueue(resultString);
      }
    }
  }, [output]);

  useEffect(() => {
    if (queue.length > 0 && !isAnimating) {
      const item = queue.shift();
      setQueue([...queue]); // Update state to trigger re-render
      appendMessage(item);
      if (typeof item === 'string') {
        animateText(item);
      } else {
        setIsAnimating(false); // No animation for non-string items
      }
    }
  }, [queue, isAnimating]);

  useEffect(() => {
    updateIsAnimating(isAnimating);
  }, [isAnimating, updateIsAnimating]);

  const appendMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const animateText = (message) => {
    const typingSpeed = 15;
    let currentIndex = 0;

    const animate = () => {
      if (currentIndex <= message.length) {
        setIsAnimating(true);
        const currentMessage = message.substring(0, currentIndex);
        setMessages((prevMessages) => [...prevMessages.slice(0, -1), currentMessage]);
        currentIndex++;
        setTimeout(animate, typingSpeed);
      } else {
        setIsAnimating(false);
      }
    };

    animate();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  return (
    <div 
    style={{ height: `${height}px`, width: '100%',  maxWidth: `${width}px`}}
    className="flex flex-col ml-5 py-5 rounded-sm overflow-y-scroll overflow-x-hidden"
    >
      {messages.map((message, index) => (
        <div className='flex  my-5' key={index}>
          <img ref={chatEndRef} src={botIcon} alt="" className='w-10 h-10 rounded-full mr-5' />
          {index === messages.length - 1 ? (
            <div className='text-slate-500'>{message}</div>
          ) : (
            <div style={{ opacity: 0.5 }}>{message}</div>
          )}
        </div>
      ))}
    </div>
  );
}

export default UniversalChat;
