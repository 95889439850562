import React, { useState,useEffect } from 'react';
import axios from 'axios';

import { FaSearch } from 'react-icons/fa';
import { ToolTip } from '../component/ToolTip'; 

export const Search = ({fileName,fileType,searchResponse,resetTable}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [previousSearchTearm,setPreviousSearchTerm] = useState("")

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log(previousSearchTearm)
  };

  const handleSearch = (event) => {
    event.preventDefault(); 
    console.log(`Searching for: ${searchTerm}`);
    setPreviousSearchTerm(searchTerm);
    if(searchTerm !== ""){
        queryFileData(fileName,searchTerm)
    }
  };

  useEffect(() => {
    if(previousSearchTearm === ""){
        resetTable();
    }
  }, [previousSearchTearm,resetTable]);

  async function queryFileData(fName, query) {
        try {
            const qeuryToSend = {
                queryData: {
                    "fileName": `${fName}.${fileType}`,
                    "query": query
                }
            };
            
            //{ "queryData": {"fileName": "train.csv", "query":"sql: select * from train limit 5"}}
            // {
            //     "queryData": {
            //       "fileName": "train",
            //       "query": "{ \"queryData\": {\"fileName\": \"train.csv\", \"query\":\"sql: select * from train limit 5\"}}"
            //     }
            //   }

            console.log("Query Send:", qeuryToSend);

            const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/get_QueryFileData`, qeuryToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Checking response status
            if (response.status === 200) {
                // Logging the received data
                console.log(response.data)
                if(response.data !== "Please check your SQL query! Is it correct?"){
                    searchResponse(response.data);
                }
                 
            } else {
                // Throwing error if status is not 200
                throw new Error('Received a non-200 status code: ' + response.status);
            }
        } catch (error) {
            // Logging any errors that occur during the request or handling
            console.error('Error:', error);
            throw error;
        }
    }


  return (
    <form className={`flex items-center justify-end relative mt-2`}  onSubmit={handleSearch}>
      <div className="flex flex-grow h-[50px] ml-10 min-w-[200px] items-center bg-white border border-gray-500 rounded-lg relative">
        <button
          type="submit"
          className="absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer p-2 transition-all hover:bg-point-blue hover:scale-110"
          style={{ outline: 'none' }}
        >
          <FaSearch className="text-gray-500"/>
        </button>
        <input
          type="text"
          placeholder="Chat and search.."
          value={searchTerm}
          onChange={handleSearchChange}
          className="md:[500px] lg:w-[300px] xl:w-[550px] 2xl:w-[1130px] pl-12 py-1 text-sm focus:text-gray-700 focus:bg-white focus:border-slate-800 outline-none"
          style={{ borderRadius: 'inherit'}}
        />
      </div>
      
      <ToolTip text="You can chat and search with any search token, you can write sql quries." required={false} iconSize="2rem" />
    </form>
  );
}
