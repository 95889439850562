import React, { useState,useEffect,useRef } from 'react';
import axios from 'axios';

import {ToolTip} from "../component/ToolTip";
import UniversalChat from '../component/UniversalChat';


export default function OnlineShopperModel() {
    const [formData, setFormData] = useState({
        Administrative: 0,
        Administrative_Duration: 0.0,
        Informational: 0,
        Informational_Duration: 0.0,
        ProductRelated: 1,
        ProductRelated_Duration: 0.0,
        BounceRates: 0.2,
        ExitRates: 0.2,
        PageValues: 0.0,
        SpecialDay: 0.0,
        Month: "",
        OperatingSystems: "",
        Browser: "",
        Region: "",
        TrafficType: "",
        VisitorType: "",
        Weekend: "",
        Revenue: ""
    });

    const [formErrors, setFormErrors] = useState({
        Administrative: "",
        Administrative_Duration: "",
        Informational: "",
        Informational_Duration: "",
        ProductRelated: "",
        ProductRelated_Duration: "",
        BounceRates: "",
        ExitRates: "",
        PageValues: "",
        SpecialDay: "",
        Month: "",
        OperatingSystems: "",
        Browser: "",
        Region: "",
        TrafficType: "",
        VisitorType: "",
        Weekend: "",
        Revenue: "",
      });

      const inputHints = {
        Administrative: "The number of administrative pages visited by the visitor during the session. Administrative pages typically include account settings, profile management, or similar.",
        Administrative_Duration: "The total duration (in seconds) spent by the visitor on administrative pages during the session. This indicates the amount of time the visitor engaged with administrative content.",
        Informational: "The number of informational pages visited by the visitor during the session. Informational pages may include FAQs, terms of service, or other non-product-related content.",
        Informational_Duration: "The total duration (in seconds) spent by the visitor on informational pages during the session. This reflects the amount of time spent engaging with non-product-related content.",
        ProductRelated: "The number of pages related to products or services visited by the visitor during the session. Product-related pages include product listings, descriptions, or reviews.",
        ProductRelated_Duration: "The total duration (in seconds) spent by the visitor on pages related to products or services during the session. This indicates the engagement with product-related content.",
        BounceRates: "The bounce rate of the website, expressed as a percentage. The bounce rate measures the percentage of visitors who enter the site and then leave without further interaction, such as clicking on links or navigating to other pages.",
        ExitRates: "The exit rate of the website, expressed as a percentage. The exit rate measures the percentage of visits to a web page where it was the last page viewed before ending the session.",
        PageValues: "The average value of the pages visited by the visitor before completing an e-commerce transaction. This metric helps assess the effectiveness of different pages in generating revenue.",
        SpecialDay: "A numeric value indicating the closeness of the visit to a special day, such as Mother's Day or Valentine's Day. Higher values indicate closer proximity to a special day.",
        Month: "The month of the year during which the visit occurred. This can provide insights into seasonal trends or patterns in visitor behavior.",
        OperatingSystems: "The operating system used by the visitor's device. Examples include Windows, macOS, Linux, iOS, and Android.",
        Browser: "The web browser used by the visitor to access the website. Examples include Chrome, Firefox, Safari, Edge, and Internet Explorer.",
        Region: "The geographical region of the visitor. This can be a country, state, province, or other regional designation.",
        TrafficType: "The type of traffic that led the visitor to the website. This could include organic search, paid advertising, direct traffic, referral traffic, and others.",
        VisitorType: "Indicates whether the visitor is a returning visitor or a new visitor. Understanding visitor type can help tailor the user experience and marketing strategies.",
        Weekend: "A boolean value indicating whether the date of the visit falls on a weekend (Saturday or Sunday). This can help identify differences in visitor behavior based on weekdays versus weekends.",
        Revenue: "The target variable indicating whether the visitor ended up with shopping (TRUE) or not (FALSE). This is the variable to be predicted in the classification problem."
    };
    

    const [inputValid,setValid] = useState("");
    const [parentIsAnimating, setParentIsAnimating] = useState(false);
    const [result,setResult] = useState("");

    const updateIsAnimating = (value) => {
        
        setParentIsAnimating(value);
      };

      const handleInputChange = (event) => {
        const { name, value, type } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'number' ? parseFloat(value) || 0 : value
        }));
        // const error = validateField(name, value);
        // setFormErrors(prev => ({
        //     ...prev,
        //     [name]: error
        // }));
    };

    const validateField = (name, value) => {
        if (['OperatingSystems', 'Browser', 'Region', 'TrafficType', 'VisitorType', 'Weekend', 'Month', 'Revenue'].includes(name) && value === "") {
            return 'This field is required';
        } else if (value < 0 && !['Administrative', 'Informational', 'ProductRelated'].includes(name)) {
            return 'Value must be zero or greater than zero';
        } else {
            return ''; // No error
        }
    };
    
    useEffect(() => {
        if (result !== "" && inputValid !== "") {
            const timer = setTimeout(() => {
                setResult("");
                setValid("");
            }, 5000); // Clear after 5000 milliseconds (5 seconds)
            return () => clearTimeout(timer);
        }
    }, [result, inputValid]);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form from submitting the traditional way
        if(parentIsAnimating){
            return;
        }

        let hasErrors = false;
        const newErrors = {};
    
        // Validate all fields before submission
        Object.entries(formData).forEach(([key, value]) => {
            const error = validateField(key, value);
            if (error) {
                newErrors[key] = error;
                hasErrors = true;
            }
        });
    
        setFormErrors(newErrors);
    
        if (!hasErrors) {
            console.log("Form is valid, submit data:", formData);
            setValid("Thank you for giving me the necessary information required. Please wait while I analyze.");
            sendShopperData(formData);
            // Clear errors if any
            setFormErrors({});
        } else {
            console.log("Form has errors, cannot submit", newErrors);
        }
    };
    

    const generateOptions = (count) => [...Array(count).keys()].map(i => i + 1);
    
    const categoricalOptions = {
        OperatingSystems: generateOptions(8),
        Browser: generateOptions(13),
        Region: generateOptions(9),
        TrafficType: generateOptions(20),
        VisitorType: ["New_Visitor", "Returning_Visitor", "Other"],
        Weekend: ["True", "False"],
        Month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        Revenue: ["True", "False"]
    };

    async function sendShopperData(formData) {
        console.log({"formData":{formData}})
        try {
            const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/mlapps/online_shopper_model`, {formData});
        
            if (response.status === 200) {
                console.log('Form data submitted successfully:', formData);
                
                // Example: Update state based on response
                console.log(response.data);
                setResult(response.data);

                
            } else {
                throw new Error('Received a non-200 status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error:', error);
            // Optionally handle the error in UI, such as showing an error message
            
        }
    }

    const formRef = useRef(null);
    const [formHeight, setFormHeight] = useState(0);
    const [formWidth, setFormWidth] = useState(0);
    
    useEffect(() => {
      if (formRef.current) {
        const height = formRef.current.clientHeight;
        const width = formRef.current.clientWidth;
        setFormHeight(height);
        setFormWidth(width);
      }
    }, []);

    return (
        <div className="container p-4">
            <h1 className='text-4xl text-defualt-b font-bold flex xl:justify-center xl:items-center pt-5'>Online Shopper</h1>
            <div className='xl:flex sm:flex-col 2xl:flex-row items-start my-5 bg-white rounded-lg shadow-xl lg:w-[1000px] xl:w-[1250px] 2xl:w-[2000px]'>
            <form ref={formRef} className="grid grid-cols-1 md:grid-cols-2 gap-4 p-5 bg-white shadow-2xl border-4 border-defualt-b rounded-xl" onSubmit={handleSubmit}>
                {Object.entries(formData).map(([key, value]) => (
                    <div key={key} className="flex flex-col xl:flex-row justify-start items-start xl:items-center my-2">
                        <label htmlFor={key} className="w-[212px] font-bold text-2xl text-slate-500">
                            {key.replace(/_/g, ' ')}
                        </label>
                        {key in categoricalOptions ? (
                            <select
                                className="w-full md:w-[256px] font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-lg xl:mx-5 bg-white"
                                id={key}
                                name={key}
                                onChange={handleInputChange}
                                value={value}
                            >
                                <option value="">Choose {key.replace(/_/g, ' ')}</option>
                                {categoricalOptions[key].map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                className="w-full md:w-[256px] h-[43px] xl:mx-5 font-medium p-2 border-2 border-defualt-b rounded-lg text-defualt-b text-lg  bg-white"
                                id={key}
                                name={key}
                                type="number"
                                placeholder="0"
                                value={value}
                                onChange={handleInputChange}
                            />
                        )}
                        <ToolTip required={true} text={`${inputHints[key] || `Enter ${key.replace(/_/g, ' ')}`}.`} iconSize='2rem'/>
                        {formErrors[key] && <p className="text-red-500">{formErrors[key]}</p>}
                    </div>
                    
                ))}
                <button type="submit" className="py-2 px-4 w-auto xl:w-[500px] mt-4 bg-defualt-b hover:bg-point-blue hover:scale-105 text-lg font-bold transition-all duration-200 text-white rounded-md">Submit</button>
            </form>
            <UniversalChat error={formErrors} output={result} updateIsAnimating={updateIsAnimating} receviedText={inputValid} height={formHeight} width={"600"}/>
            </div>
        </div>
    );
}
