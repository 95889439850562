import Papa from "papaparse";
import { useState,useEffect} from "react";
import axios from "axios";
import {FcDataSheet} from "react-icons/fc"
import {PiFolderOpen} from 'react-icons/pi'

function FileUpload(Props) {
  const [parsedData, setParsedData] = useState([]);

  // Preprocessing data
  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [rowValues, setValues] = useState([]);
  const [fileType, setfileType] = useState("test");
  const [fileName, setfileName] = useState("datafile");
  const [curFile,setCurFile] = useState();
  const [components, setComponents] = useState([false]);
  const [data,setData] = useState([])

  

  const changeHandler = (event) => {
    if (event.target.files[0] === undefined) return;
    let fName = event.target.files[0].name.split(".")[0];
    let fType = event.target.files[0].name.split(".").pop();
    console.log(fType);
    setfileType(fType);
    setfileName(fName);
    setCurFile(event.target.files[0]);
    console.log(event.target.files[0]);
    setComponents([false]);

    if (fType === "csv") {
      //console.log('csv');
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];
          //console.log(results.data);
          // Iterating data to get column name and their values
          results.data.map((d) => {
            //console.log(Object.values(d));
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
            return 0;
          });

          // Parsed Data Response in array format

          setParsedData(results.data);
          // console.log("Data object:", results.data);
          //console.log('Data object to JSON:',JSON.stringify(results.data));
          //parsedData=results.data;

          // Filtered Column Names
          //console.log(rowsArray[0]);
          setTableRows(rowsArray[0]);
          //tableRows = rowsArray[0];

          // Filtered Values
          //console.log(valuesArray);
          setValues(valuesArray);
        },
      });
      // console.log("File upload props", Props);
    } else if (fType === "json") {
      const fileReader = new FileReader();
      fileReader.readAsText(event.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        //console.log("e.target.result", e.target.result);
        var DataObject = JSON.parse(e.target.result);
        //['AvgTemperature']
        //console.log("DataObject:",Object.entries(DataObject));
        //var result = Object.keys(DataObject).map((key) => [Number(key), DataObject[key]]);
        let result = [];
        let dcol = 0;
        //console.log("DataObject:", DataObject);
        //setParsedData(DataObject);
        for (const [key, value] of Object.entries(DataObject)) {
          console.log(`${key}: ${value}`);
          //let dum=[];
          //result.push([])

          for (const [keyin, valuein] of Object.entries(value)) {
            //console.log(`${keyin}: ${valuein}`);
            //console.log(row,col);
            var x = `${valuein}`;
            //console.log(x);
            if (dcol === 0) result.push([]);

            result[Number(keyin)].push(x);
            //drow.push(x);
          }
          dcol = dcol + 1;
        }
        //console.log("result:",result);

        var colNames = Object.keys(DataObject);
        var DataObjectUp = [];
        for (var i = 0; i < result.length; i++) {
          //console.log(result[i]);
          var dataObj = {};
          for (var j = 0; j < result[i].length; j++) {
            dataObj[colNames[j]] = result[i][j];
          }
          //console.log(dataObj);
          DataObjectUp.push(dataObj);
        }
        console.log("DataObjectUp", DataObjectUp);
        setParsedData(DataObjectUp);
        setTableRows(Object.keys(DataObject));
        setValues(result);

        //parsedData= DataObject;
        //tableRows =Object.keys(DataObject);
        //rowValues=result;

        //setFiles(e.target.result);
      };
    } else {
      return;
    }
  };

  const uploadFile = (event) => {
    //console.log(typeof(event))
    if(typeof(event) !== 'string'){
      event.preventDefault();
    }
   
    let message = "";
    Props.onFileLoad({
      parsedData,
      tableRows,
      rowValues,
      fileType,
      components,
      message,
      fileName,
    });
    //console.log(parsedData,tableRows,rowValues,fileType,components,message,fileName);
  };

  //Exsisting file fetching
  const [showDiv, setShowDiv] = useState(false);
  
    const showFilesDiv = () => {
      //sendFileRequest();
      setShowDiv(!showDiv);
    };

  const [files, setFiles] = useState([]);

  async function sendFileRequest() {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/get_fileNames`);
  
      if (response.status === 200) {
        const data = response.data;
        console.log(data)
        setFiles(data); 
        return data;
      } else {
        throw new Error('Received a non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  useEffect(() => {
    // Call your function here
    sendFileRequest()
    //console.log("requested")
    //console.log(files);
  }, []);
  
  useEffect(() => {
    // Call your function here
    uploadFile("Exfile");
    //console.log("requested")
  }, [parsedData]);

  useEffect(()=>{
    //console.log("data before: ",data);
    //console.log("Lenght of data: ",data.length)

    if(!Array.isArray(data)){
      //console.log("data is not null: ",data)
      //console.log("parsed:",data.parsedData)
      setParsedData(data.parsedData);
      //console.log("after set: ",parsedData)
      setTableRows(data.tableRows);
      setValues(data.rowValues);
      setfileType(data.fileType);
      setComponents([false]);
     
    }

  },[data])

//  useEffect to send uploaded file to server

  useEffect(() => {
    
    async function sendFileToServer() {
      try {
        
        const formData = new FormData();
        //console.log(curFile);
        formData.append('file', curFile);
        console.log(formData);

        const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/upload_file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        //const response ="Ok"

        if (response.status === 200) {
          console.log('File uploaded successfully');
          // Handle successful response
        } else {
          throw new Error('File upload failed with status code: ' + response.status);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error case
      }
      
    }

    
    if (curFile) {
      sendFileToServer();
    }
  }, [curFile]); 


  async function getFileData(fileName) {
    try {
      const dataFileName = JSON.stringify({fileName});
      //console.log(dataFileName);
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/get_fileData`, {
        dataFileName,
      },{
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        const data = response.data;
        const filenameWithoutExtension = fileName.replace(/\.[^.]+$/, '');
        setfileName(filenameWithoutExtension);
        showFilesDiv();
        setData(data)
        return data;
      } else {
        throw new Error('Received a non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }


  return (
    <>
      
      <div className="flex flex-col items-center justify-center m-5">
        <h3 className="text-center text-sky-500 capitalize">Select File to Start</h3>
        <form onSubmit={uploadFile} className="w-full max-w-md">
          <input
            type="file"
            name="file"
            onChange={changeHandler}
            accept=".csv, .json"
            className="px-5 py-3 font-medium text-medium text-grey-600 bg-white border border-gray-500 rounded-lg transition duration-250 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-800 mb-3"
          />
          <div className="flex flex-row gap-3">
            <button type="submit" className="flex-1 btn">
              Upload
            </button>
            <button type="button" className="flex-1 btn" onClick={showFilesDiv}>
              {showDiv ? "Cancel" : "Add existing file"}
            </button>
          </div>
        </form>
      </div>

      
      <div className="flex flex-col justify-center items-center">
      
      
        {showDiv?<div className="absolute h-full w-full opacity-30 bg-black blur-sm"></div>:null}
        
          <div className={showDiv?"bg-gray-300 rounded absolute z-10 transform scale-100 transition-all duration-300":"bg-gray-300 rounded absolute z-10 transform scale-0"}
            style={{ height: "600px", width: "850px" }}>
              <div className="bg-gray-200 rounded-t-lg flex justify-between">
                <PiFolderOpen className="mx-3 mt-1" style={{ height: "35px", width: "35px" }}/>
                <button className="bg-defualt-b hover:bg-point-blue rounded-full px-2 m-3" onClick={showFilesDiv}>X</button>
              </div>
              <ul className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-1">
                {files.map((file, index) => {
                  return (
                    <li key={index} className="max-w-150 p-3">
                      <div onClick={()=> getFileData(file)} className="rounded-lg bg-defualt-b  
                      hover:bg-point-blue border-gray-300 border-2 hover:border-black flex flex-col items-center justify-center cursor-pointer ">
                          <FcDataSheet
                            style={{ height: "75px", width: "75px" }}
                          />
                        <p className="text-black max-w-[135px] overflow-hidden truncate ...">{file}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
      </div>
    </>
  );
}
export default FileUpload;
