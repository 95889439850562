import React from 'react';
import { NavLink } from 'react-router-dom';

import { GiHealthNormal } from "react-icons/gi";
import { FaGear } from "react-icons/fa6";
import { PiBuildingsFill } from "react-icons/pi";



 const Icons = [<GiHealthNormal className='text-4xl' />,<FaGear className='text-4xl'/>,<PiBuildingsFill className='text-4xl'/>]

export default function CategoryCard({ categoryTitle, description, buttonLink ,index}) {
    


  return (
    <div className="max-w-sm overflow-hidden shadow-lg bg-white transition-transform duration-200 hover:scale-105 mr-5 mb-5 sm:flex flex-col">
    <div className="p-6 flex-grow">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl sm:text-4xl font-bold text-blue-900">{categoryTitle}</h1>
        {Icons[index]}
      </div>
      <p className="text-gray-700 mb-auto">{description}</p>
    </div>
    <NavLink
      to={buttonLink}
      className="border-2 border-yellow-300 text-2xl sm:text-3xl p-3 sm:p-5 text-blue-900 hover:bg-yellow-300 transition-colors duration-300 flex items-center justify-center m-5"
    >
      Explore
    </NavLink>
  </div>

  );
}
