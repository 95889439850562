import React,{useState,useEffect,useRef} from 'react'
import axios from 'axios';
import PieChart from '../VisActComponents/PieChart';

import { MdOutlineFileUpload } from "react-icons/md";
import uploadIMG from "../Assets/upload.jpg";
import UniversalChat from '../component/UniversalChat';

function SkinDiseaseModule() {
  document.title = "Skin Disease Module";

    const [selectedImage, setSelectedImage] = useState(null);
    const [input,setInput] = useState("");
    const [success,setSuccess] = useState();
    

    async function SendImageToBackend() {
      try {
        if (!selectedImage) {
          console.error('No image selected');
          return;
        }
    
        setInput("I have successfully received your input. Please wait a little bit while I analyze.");
    
        const formData = new FormData();
        formData.append('image', selectedImage, 'uploaded-image.jpg');
    
        const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/mlapps/getSkinDisease`, formData);
       
        if (response.status === 200) {
          console.log('Image submitted successfully.');
          const data = response.data;
          const output = processSuccess(JSON.stringify(data));
          console.log(output);
          setSuccess(output);
        } else {
          throw new Error('Received a non-200 status code: ' + response.status);
        }
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
    

      const processSuccess = (Success) => {
        if (Success && Success.length > 0) {
         
          const success = JSON.parse(JSON.parse(Success));
          //const success = Success;
          //success = Array(success)
          //const success = [{"Diseases Name":"Normal","Probability":0.9997248054},{"Diseases Name":"Vitiligo","Probability":0.0002662345},{"Diseases Name":"Fungal Infections","Probability":0.0000079405}]
      
          const formattedData = success.map((disease) => {
            const probabilityPercentage = (disease.Probability * 100).toFixed(2);
            return `${disease["Diseases Name"]}: ${probabilityPercentage}%`;
          });
      
          const resultString = formattedData.join(', ');

          console.log(resultString)
      
          const dataWithPercentages = success.map(item => ({
            "Diseases Name": item["Diseases Name"],
            "Probability": item["Probability"] * 100
          }));
      
          const chartData = {
            x_axis: 'Diseases Name',
            y_axis: 'Probability',
            parsedData: dataWithPercentages
          };
      
          // Return JSX directly
          return (
            <>
              <div className='w-[546px] h-[430px] pb-[100px]'>
                <p>Based on my analysis your results are:</p>
                <p className='font-bold text-xl'>{resultString}</p>
                <h1 className='text-lg text-defualt-b flex justify-center items-center'>Disease vs Probability</h1>
                <PieChart chartData={chartData} />
              </div>
              <div className='mb-[100px]'></div>
            </>
          );
        }
      
        // Return null or any default JSX if Success is not processed
        return null;
      };
      

      const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            resizeImage(file, 200, (resizedImage) => {
                setSelectedImage(resizedImage);
                // If you need to upload this image or use it as a file, you might need further processing
                // For example, converting blob to File object if necessary
                // const file = new File([resizedImage], "filename.jpg", {type: "image/jpeg"});
            });
        }
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      if(parentIsAnimating){
        return;
      }
      SendImageToBackend() 
      
      console.log('Selected Image:', selectedImage);
    };

      
    const [parentIsAnimating, setParentIsAnimating] = useState(false);

    const updateIsAnimating = (value) => {
      
      setParentIsAnimating(value);
    };

     // Function to resize image
     const resizeImage = (file, targetSizeKB, callback) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
          const imgElement = document.createElement("img");
          imgElement.src = event.target.result;
          imgElement.onload = () => {
              const canvas = document.createElement("canvas");
              const maxDimension = 800; // Start resizing from this dimension
              canvas.width = imgElement.width;
              canvas.height = imgElement.height;

              const ctx = canvas.getContext("2d");
              ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

              let quality = 1.0; // Start with the best quality
              let imageDataUrl = canvas.toDataURL("image/jpeg", quality);

              // Reduce quality to decrease file size
              while (imageDataUrl.length > targetSizeKB * 1024 && quality > 0.1) {
                  quality -= 0.1;
                  imageDataUrl = canvas.toDataURL("image/jpeg", quality);
              }

              // Convert Data URL to Blob
              fetch(imageDataUrl)
                  .then(res => res.blob())
                  .then(blob => callback(blob));
          };
      };
  };

   console.log(input);

   const formRef = useRef(null);
   const [formWidth, setFormWidth] = useState(0);
    
    useEffect(() => {
      if (formRef.current) {
        const width = formRef.current.clientWidth;
        setFormWidth(width);
      }
    }, []);

  return (
    <div className='flex flex-col justify-center items-center '>
      <h1 className='px-[50px] xl:px-0 text-4xl text-defualt-b font-bold flex justify-center items-center pt-5'>Skin Disease Detection</h1>
      <div className='md:flex justify-center xl:p-10'>
      <form className='shadow-xl w-[340px] xl:w-[600px] h-[700px] p-5 xl:p-10' ref={formRef} onSubmit={handleSubmit}>
      <div className='border-2 rounded-lg p-2 flex items-center justify-center'>
        <label htmlFor="imageInput" className='m-2 text-2xl cursor-pointer flex'>
            Select an image to start
            <MdOutlineFileUpload className='text-4xl ml-2 rounded-lg text-white transition-all duration-150 bg-defualt-b hover:bg-point-blue hover:scale-105' />
        </label>
        <input
            type="file"
            id="imageInput"
            accept=".jpg, .jpeg, .png"
            onChange={handleImageChange}
            className='h-full w-full opacity-0 z-10 relative hidden'
        />
      </div>
      <div>
      {selectedImage ? (
    <div className="">
      <div className="flex items-center justify-center flex-col px-5 py-1">
        <img
          src={URL.createObjectURL(selectedImage)}
          alt=""
          style={{ width: '420px', height: '400px' }}
          className="m-3 border-4 border-defualt-b rounded-lg"
        />
        <h1 className="text-lg ml-3 pt-3">You want to upload this image?</h1>
      </div>
      <div className="flex justify-center items-center">
        <button
          type="submit"
          className="py-2 px-4 mt-4 bg-defualt-b hover:bg-point-blue hover:scale-105 text-2xl font-bold transition-all duration-200 text-white rounded-md"
        >
          Submit
        </button>
      </div>
    </div>
  ) : (
    <div className='flex items-center justify-center'>
      <img
        src={uploadIMG}
        alt=""
        style={{ width: '550px', height: '350px',paddindTop:"10px" }}
        className="mt-20"
      />
    </div>
  )}
        
      </div>
          
    </form>
    
    <UniversalChat receviedText={input} output={success} updateIsAnimating={updateIsAnimating} error={""} width={formWidth} height={"700px"}/>
    </div>
    </div>
  )
}

export default SkinDiseaseModule