import React, { useState, useRef, useEffect } from 'react';
import { MdBarChart, MdPieChart } from 'react-icons/md';

let activePopupSetter = null;

const ToolTipTable = ({ children, columnIndex, getUserInput }) => {
  const [hoverPopup, setHoverPopup] = useState(false);
  const [clickPopup, setClickPopup] = useState(false);
  const popupRef = useRef(null);
  const buttonRef = useRef(null);

  const openPopup = (persistent = false) => {
    if (activePopupSetter && activePopupSetter !== setClickPopup) {
      activePopupSetter(false); // Close any currently open popup
    }
    if (persistent) {
      setClickPopup(true);
      activePopupSetter = setClickPopup;
    } else if (!clickPopup) {
      setHoverPopup(true);
    }
  };

  const closePopup = () => {
    setClickPopup(false);
    setHoverPopup(false);
    if (activePopupSetter === setClickPopup) {
      activePopupSetter = null;
    }
  };

  const onMouseEnter = () => openPopup();
  const onMouseLeave = () => setHoverPopup(false);
  const onClick = () => openPopup(true);

  const onScroll = () => {
    if (hoverPopup || clickPopup) {
      closePopup();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true);
    return () => window.removeEventListener('scroll', onScroll, true);
  }, [hoverPopup, clickPopup]);

  const positionPopup = () => {
    if (buttonRef.current && popupRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();
      const topPosition = buttonRect.top - popupRect.height - 45;
      const leftPosition = buttonRect.left + (buttonRect.width / 2) - (popupRect.width / 2);

      popupRef.current.style.top = `${topPosition}px`;
      popupRef.current.style.left = `${leftPosition}px`;
    }
  };

  useEffect(() => {
    if (hoverPopup || clickPopup) {
      positionPopup();
    }
  }, [hoverPopup, clickPopup]);

  const showPopup = hoverPopup || clickPopup;

  const handleChartClick = (chartType) => {
    const chartTypeName = chartType === 'PieChart' ? "Pie Chart" : "Bar Chart";
    
    getUserInput({
      x_axis: children.props.children, // This is how you access the column name
      y_axis: '', // Empty as per requirements
      message: `Chart for ${children.props.children}`,
      chatType: [chartTypeName]
    });
    closePopup(); 
  };
  

  return (
    <div className="relative inline-block">
      <div
        ref={buttonRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {children}
      </div>
      {showPopup && (
        <div ref={buttonRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <div ref={popupRef} className="absolute p-3 bg-[#F2F2F2] rounded-lg shadow-lg"
             style={{ position: 'fixed', zIndex: 1000 }}
             >
          <button onClick={closePopup} className="absolute right-2 top-1 text-lg transition-all duration-150 hover:scale-105 hover:text-point-blue">✕</button>
          <div className="flex justify-around p-2">
          <button
            className="flex items-center p-2 transition-all duration-150 hover:scale-105 hover:text-point-blue"
            onClick={() => handleChartClick('PieChart')}
          >
            <MdPieChart className="mr-2"/> Pie Chart
          </button>
          <button
            className="flex items-center p-2 transition-all duration-150 hover:scale-105 hover:text-point-blue"
            onClick={() => handleChartClick('BarChart')}
          >
            <MdBarChart className="mr-2"/> Bar Chart
          </button>
          </div>
        </div>
        </div>
      )}
    </div>
  );
};

export default ToolTipTable;
