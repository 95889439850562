import React from 'react'
import ContactForm from '../component/ContactForm'

export default function Feedback() {
  document.title = "Feedback";

  return (
    <div>
       <div className='p-10 shadow-2xl'>
        <ContactForm/>
       </div>
    </div>
  )
}
