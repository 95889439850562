import React from 'react'
import { NavLink } from 'react-router-dom';


const routes = [
  { path: "/machineMaintenance", name: "Machine Maintenance", desc:"Find out if your machine needs maintenance using our machine learning model." },
  { path: "/onlineShopper", name: "Online Shopper", desc:"Online Shopper Test" }
];


export default function MachineMaintananceCategory() {
  document.title = "Machine Maintenance Services";

  return (
    <div className="flex flex-col justify-center items-center gap-4">
    <h1 className="text-4xl text-defualt-b font-bold flex justify-center items-center pt-5">Machine Maintanance Demos</h1>
    <div className="grid grid-cols-4 gap-4 px-[100px]">
      {routes.map((route, index) => (
        <div key={index} className="border rounded-lg overflow-hidden shadow-lg hover:shadow-xl hover:scale-105 transition-all duration-300 relative bg-white p-5">
        <NavLink to={route.path} className="p-4 text-lg font-semibold text-default hover:text-point-blue transition-colors duration-300">
            <div className="flex justify-between items-center">
                {route.name}
            </div>
            <p className="text-sm text-gray-600 mt-2">
                {route.desc}
            </p>
        </NavLink>
        
    </div>
    
      ))}
    </div>

  </div>
  )
}
