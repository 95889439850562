import { useState,useRef,useEffect } from "react";
import ToolTipTable from "../component/ToolTipTable";

import {Search} from "../VisActComponents/Search";

function ShowData(Props) {
  const [rowNumStart, setRowNumStart] = useState([1]);
  const [rowNumEnd, setRowNumEnd] = useState([15]);

  // const { getUserInput, tableData } = Props;
 const [queryDataHeading,setQueryDataHeading] = useState(Props.tableData.tableRows)
 const [queryDataRow,setQueryDataRow] = useState(Props.tableData.rowValues)
  // const displayRows = (event) => {
  //   event.preventDefault();
  //   //let message="";
  // };
  // console.log(Props.fileName,Props.fileType)
  
  const changeHandler = (event) => {
    console.log(event.target.name);
    console.log(event.target.value);
    
    if (event.target.name === "rowNumStart") 
      setRowNumStart(event.target.value);
    else setRowNumEnd(event.target.value);

    //if (event.target.files[0] === undefined) return;
  };

  
  const searchResponse = (searchOutput) => {
    console.log(searchOutput)
    //setQueryData(searchOutput)
    setQueryDataHeading(searchOutput.tableRows);
    setQueryDataRow(searchOutput.rowValues);
  }

  const resetTable = () => {
    //setQueryData(searchOutput)
    console.log("Reset table is being called")
    setQueryDataHeading(Props.tableData.tableRows);
    setQueryDataRow(Props.tableData.rowValues);
  }
  

  return (
    <>
      {/*<h4 className="text-lg text-[#1e2a3c] font-bold">Display Rows</h4>

      <h3 className="text-lg text-[#1e2a3c] font-medium">
        Display Rows {rowNumStart} to {rowNumEnd}
      </h3>
      */}

  <div className="ml-3 md:flex justify-start items-center p-3 ">
    <form className="flex h-[50px]">
      <div className="flex">
        <p className="my-auto w-[60px] text-sm mr-1">Display Rows</p>
        <input
          type="number"
          name="rowNumStart"
          value={rowNumStart}
          onChange={changeHandler}
          className="px-2 py-1 w-[70px] mr-2 text-sm bg-white border border-gray-500 rounded-lg focus:text-gray-700 focus:bg-white focus:border-slate-800"
        />
      </div>
      <div className="flex">
        <p className="my-auto w-[50px] text-sm flex justify-center">To Rows</p>
        <input
          type="number"
          name="rowNumEnd"
          value={rowNumEnd}
          onChange={changeHandler}
          className="px-2 py-1 w-[70px] mr-1 text-sm bg-white border border-gray-500 rounded-lg focus:text-gray-700 focus:bg-white focus:border-slate-800"
        />
      </div>
    </form>
    <div className="flex flex-grow items-center">
      <Search resetTable={resetTable} fileName={Props.tableData.fileName} fileType={Props.tableData.fileType} searchResponse={searchResponse}/>
    </div>
  </div>


      <div className="overflow-x-auto overflow-y-auto h-[500px]">
      <table className="ml-3 table leading-[.08] ">
        <thead>
          <tr>
          {queryDataHeading.map((rows, index) => {
            return (
              <th className="capitalize text-lg font-bold text-center" key={index}>
                <ToolTipTable tooltipMessage={`Click on this column to generate chart for ${rows}`} getUserInput={Props.getUserInput}>
                  <button className="p-2 hover:bg-gray-200 rounded focus:outline-none focus:ring">
                    {rows}
                  </button>
                </ToolTipTable>
              </th>
            );
          })}
          </tr>
        </thead>
        {
          <tbody>
            {queryDataRow
              .slice(rowNumStart-1, rowNumEnd)
              .map((value, index) => {
                //console.log(index);
                //if(index<10)
                return (
                  <tr key={index}>
                    {value.map((val, i) => {
                      return (
                        <td className="text-center" key={i}>
                          {val}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        }
      </table>
      </div>
    </>
  );
}

export default ShowData;
