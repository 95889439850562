import React,{useState,useEffect,useRef} from 'react';
import axios from 'axios';
import UniversalChat from '../component/UniversalChat';

export default function MachineMaintenanceModule() {
  document.title = "Machine Maintenance Module";

  const [values, setValues] = useState({
    type: "",
    airTemperature: 300,
    processTemperature: 309.5,
    rotationalSpeed: 2000,
    torque: 40,
    toolWear: 130,
  });

  const [machineInput,setInput] = useState("");
  const [success,setSuccess] = useState("");

  const sliders = [
    { id: 'airTemperature', label: 'Air Temperature [K]', min: 295, max: 305, step: 0.01 },
    { id: 'processTemperature', label: 'Process Temperature [K]', min: 305, max: 314, step: 0.01 },
    { id: 'rotationalSpeed', label: 'Rotational Speed [rpm]', min: 1100, max: 2900, step: 1 },
    { id: 'torque', label: 'Torque [Nm]', min: 3, max: 77, step: 0.01 },
    { id: 'toolWear', label: 'Tool Wear [min]', min: 0, max: 260, step: 1 },
  ];

  async function sendMaintenance(formData) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DJANGOAPI}/mlapps/getMachineMaintenance`, formData);

      if (response.status === 200) {
        console.log('Form data submitted successfully:', formData);

        const data = response.data;
        setSuccess(data);
        setInput("")

      } else {
        throw new Error('Received a non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error('Error:', error);
      
      throw error;
    }
  }

  function handleChange(id, value) {
    setValues(prevValues => ({
      ...prevValues,
      [id]: value
    }));
  }

  function handleTypeChange(event) {
    const { value } = event.target; 
    setValues(prevValues => ({
      ...prevValues,
      type: value 
    }));
  }
  
  
  function handleSubmit(event) {
    event.preventDefault(); 
    if(parentIsAnimating){
      return;
    }
    console.log(values); 
    setInput("Thank you for your input. Please wait while I analyze.");
    sendMaintenance(values);
  }

  const [parentIsAnimating, setParentIsAnimating] = useState(false);

    const updateIsAnimating = (value) => {
      
      setParentIsAnimating(value);
    };

    const formRef = useRef(null);
    const [formWidth, setFormWidth] = useState(0);
    
    useEffect(() => {
      if (formRef.current) {
        const width = formRef.current.clientWidth;
        console.log(width)
        setFormWidth(width);
      }
    }, []);

  return (
    <div>
      <div className='flex flex-col xl:flex-row items-start mx-3 xl:mx-20 my-5 bg-white rounded-lg shadow-xl xl:w-[1200px] 2xl:w-[1800px]'>
      <form onSubmit={handleSubmit} ref={formRef} className='flex flex-col w-[340px] md:w-[550px] p-5 bg-white shadow-2xl border-4 border-default-b rounded-xl'>
        <fieldset>
          <legend className='font-bold text-xl py-2 text-slate-500'>Product Quality Variant</legend>
          <label className='font-bold text-xl py-2 text-slate-500'><input onChange={handleTypeChange} className='w-5 h-5' type="radio" name="type" value="L" required /> Low (L)</label>
          <label className='font-bold text-xl py-2 text-slate-500 ml-3'><input onChange={handleTypeChange} className='w-5 h-5' type="radio" name="type" value="M" required /> Medium (M)</label>
          <label className='font-bold text-xl py-2 text-slate-500 ml-3'><input onChange={handleTypeChange}  className='w-5 h-5' type="radio" name="type" value="H" required /> High (H)</label>
        </fieldset>

        {sliders.map(({ id, label, min, max, step }) => (
          <React.Fragment key={id}>
            <label className="font-bold text-xl py-4 text-slate-500" htmlFor={id}>{label}: {values[id]}</label>
            <input
              type="range"
              id={id}
              name={id}
              min={min}
              max={max}
              step={step}
              value={values[id]}
              onChange={(e) => handleChange(id, e.target.value)}
              required
              className="rangeSlider shadow-md rounded-lg border-2 border-cyan-500 h-[10px] w-[250px]"
            />
          </React.Fragment>
        ))}

        <button className="py-2 px-4 w-[300px] xl:w-[500px] mt-4 bg-defualt-b hover:bg-point-blue hover:scale-105 text-lg font-bold transition-all duration-200 text-white rounded-md" type="submit">Submit</button>
      </form>
      <div>
      </div>
      <UniversalChat 
        receivedText={machineInput}
        output={success}
        updateIsAnimating={updateIsAnimating}
        height="700px"
        error=""
        width={formWidth < 400 ? `${formWidth}px` : "600px"}
        />

      </div>
    </div>
  );
}
